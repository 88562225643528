<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.product.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('menu.product.addProduct')"
        @click="addProductModal"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>

  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Product List -->
        <TableComponent
          :is-show-search="true"
          :is-show-search-with-filter="isInstitute"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.product.productList.title')"
          :header="productListHeader"
          :is-not-empty="productData.length > 0"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <template #icon>
            <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
          </template>

          <tr v-for="data in productData" :key="data.id" class="cursor-pointer">
            <td @click="getProductDetail(data.id)">{{ data.name }}</td>
            <td class="text-center" @click="getProductDetail(data.id)">
              {{ data.stock }}
            </td>
            <td @click="getProductDetail(data.id)">
              <Currency :total-in-string="data.price" />
            </td>
            <td class="text-center" @click="getProductDetail(data.id)">
              {{ data.child_product_total + data.ingredient_total }}
            </td>
            <td>
              <input
                v-model="data.is_available"
                class="mt-[0.3rem] mr-2 h-3.5 w-10 appearance-none rounded-[0.4375rem] bg-[rgba(0,0,0,0.25)] outline-none before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                type="checkbox"
                role="switch"
                @change.stop="updateProductAvailability($event, data.id)"
              />
              <!-- <input
                v-model="data.isAvailable"
                type="checkbox"
                class="form-check-input"
              /> -->
            </td>
            <td class="space-x-2">
              <span
                class="cursor-pointer font-medium text-danger"
                @click="showModalDelete(data.id)"
              >
                {{ $t('action.delete') }}
              </span>
            </td>
          </tr>
        </TableComponent>
        <!-- END: Product List -->
      </div>
    </div>

    <!-- BEGIN: Modal Product Detail -->
    <Modal
      :show="showProductDetailModal"
      size="modal-xl"
      @hidden="showProductDetailModal = false"
    >
      <ModalBody class="p-4">
        <div
          v-if="!checkEmptyObject(productDetail)"
          class="flex flex-col space-y-6 px-5 pb-8 text-center"
        >
          <div class="flex flex-col">
            <div class="flex items-center space-x-4">
              <p class="font-bold">
                <span>
                  {{ productDetail.name }}
                </span>
              </p>
              <p class="rounded-full bg-primary p-2 text-xs text-white">
                <span v-if="productDetail.category">
                  {{ productDetail.category.name }}
                </span>
                <span v-else>
                  {{ productDetail.description }}
                </span>
              </p>
            </div>
          </div>

          <div class="flex items-center justify-between space-x-2 px-4">
            <div class="flex flex-col space-y-4">
              <div class="flex justify-between space-x-10">
                <p>Harga produk</p>
                <Currency
                  :total-in-string="productDetail.price"
                  wrap-class="font-semibold"
                />
              </div>
              <div class="flex justify-between space-x-10">
                <p>Terakhir di-update</p>
                <p class="font-semibold">
                  <span v-if="productDetail.modified !== null">
                    {{ timeFromNow(productDetail.modified) }}
                  </span>
                  <span v-else> - </span>
                </p>
              </div>
              <div class="flex justify-between space-x-10">
                <p>Jumlah stok</p>
                <p class="font-semibold">
                  <span
                    v-if="
                      productDetail.stock !== undefined &&
                      productDetail.stock !== null
                    "
                  >
                    {{
                      Math.round((productDetail.stock + Number.EPSILON) * 100) /
                      100
                    }}
                  </span>
                  <span v-else>-</span>
                </p>
              </div>
            </div>
            <div class="flex max-w-fit items-center justify-end space-x-2">
              <template v-if="productDetail.images.length > 0">
                <img
                  v-for="image in productDetail.images.filter(
                    (item) => item.is_primary === true
                  )"
                  :key="image.id"
                  :src="image.image"
                  class="h-24"
                />
              </template>
            </div>
          </div>

          <div class="flex justify-end space-x-4 px-4">
            <div class="flex shadow">
              <input
                v-model="searchIngredient"
                class="w-full border-none p-2 outline-none"
                type="text"
                :placeholder="$t('formLabel.searchFoo', { foo: 'bahan' })"
              />
              <button
                class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
                @click="
                  searchingProduct('productChild', 'local', searchIngredient)
                "
              >
                <SearchIcon />
              </button>
            </div>
            <button
              class="rounded-lg bg-gray-500 px-4 text-white hover:bg-gray-700"
              @click="editProduct(productDetail)"
            >
              Edit Produk
            </button>
          </div>

          <div class="flex w-full flex-col">
            <!-- BEGIN: Product Ingredint List -->
            <TableComponent
              :is-show-search="false"
              :is-show-pagination="false"
              :show-title="false"
              :show-header="false"
              wrapper-class="p-0"
              :header="productDetailListHeader"
              :is-not-empty="productDetailChilds.length > 0"
            >
              <tr
                v-for="data in productDetailChilds"
                :key="data.id"
                class="cursor-pointer"
              >
                <td>{{ data.name }}</td>
                <td>
                  <p v-if="data.base_stock" class="space-x-2">
                    <span>{{ data.base_stock.total }}</span>
                    <span>{{ data.base_stock.unit }}</span>
                  </p>
                  <p v-else>-</p>
                </td>
                <td>
                  <p class="py-1">
                    <span
                      class="rounded-full py-1 px-4 text-center text-xs font-bold text-white"
                      :class="
                        data.type === 'product' ? 'bg-success' : 'bg-warning'
                      "
                    >
                      {{ $t(`info.${data.type}`) }}
                    </span>
                  </p>
                </td>
                <td>
                  <p v-if="data.quantity" class="space-x-2">
                    <span>{{ data.quantity.total }}</span>
                    <span>{{ data.quantity.unit }}</span>
                  </p>
                  <p v-else>-</p>
                </td>
              </tr>
            </TableComponent>
            <!-- END: Product Detail Ingredint List -->
          </div>
        </div>
      </ModalBody>
    </Modal>
    <!-- END: Modal Product Detail -->

    <!-- BEGIN: Modal Add Product -->
    <Modal :show="showAddProductModal" size="modal-lg" @hidden="closeModal">
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="productSchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveProduct"
        >
          <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex justify-between">
                <p class="font-bold">
                  <span v-if="!isEdit"> Tambah Produk </span>
                  <span v-else> Edit Produk </span>
                </p>
              </div>
            </div>
            <div class="grid grid-cols-6 gap-2">
              <div v-for="(file, key) in productImages" :key="key">
                <div
                  class="relative cursor-pointer"
                  @click="setPrimary('productImages', key)"
                >
                  <p
                    class="absolute right-0 top-0 w-fit cursor-pointer rounded-b-lg bg-black px-1 text-white hover:text-gray-100"
                    @click.stop="
                      deleteAttachment('productImages', key, file.id)
                    "
                  >
                    X
                  </p>
                  <img :src="file.image" class="h-20 w-full rounded-lg" />
                  <p
                    v-if="
                      file.is_primary &&
                      attachments.filter((item) => item.is_primary).length === 0
                    "
                    class="bg-black-300 absolute bottom-[0.10rem] w-full rounded-t-lg text-white"
                  >
                    Cover
                  </p>
                </div>
              </div>
              <div v-for="(file, key) in attachments" :key="key">
                <div
                  class="relative cursor-pointer"
                  @click="setPrimary('attachment', key)"
                >
                  <p
                    class="absolute right-0 top-0 w-fit cursor-pointer rounded-b-lg bg-black px-1 text-white hover:text-gray-100"
                    @click.stop="deleteAttachment('attachment', key)"
                  >
                    X
                  </p>
                  <img
                    :src="generateThumbnail(file.file)"
                    class="h-20 w-full rounded-lg"
                  />
                  <p
                    v-if="file.is_primary"
                    class="bg-black-300 absolute bottom-[0.10rem] w-full rounded-t-lg text-white"
                  >
                    Cover
                  </p>
                </div>
              </div>
              <label for="files" class="inline cursor-pointer">
                <input
                  id="files"
                  ref="files"
                  type="file"
                  class="hidden"
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                  @change="fileInput"
                />
                <div
                  class="flex h-20 items-center justify-center rounded-lg border-2 border-dotted text-gray-500"
                >
                  +
                </div>
              </label>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="product-name"
                  >
                    Nama Produk *
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="productName"
                    name="productName"
                  >
                    <input
                      id="productName"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.productName !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      placeholder="Nama Produk"
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.productName !== undefined">
                      {{ $t(errors.productName) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="product-description"
                  >
                    Deskripsi
                  </label>
                </div>
                <div>
                  <textarea
                    id="product-description"
                    v-model="productDescription"
                    required="required"
                    class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    placeholder="Masukkan deskripsi produk"
                  ></textarea>
                </div>
              </div>
            </div>
            <template
              v-for="(total, key) in productIngredientTotal"
              :key="total"
            >
              <template v-if="!ingredientDeletedKey.includes(key)">
                <div class="flex flex-col space-y-2">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="active"
                    >
                      Resep Produk
                    </label>
                  </div>
                  <div class="flex space-x-4">
                    <div>
                      <input
                        v-model="ingredientTypes[key]"
                        type="radio"
                        value="product"
                        class="text-primary"
                        :checked="ingredientTypes[key] !== 'ingredient'"
                      />
                      Produk
                    </div>
                    <div>
                      <input
                        v-model="ingredientTypes[key]"
                        type="radio"
                        class="text-primary"
                        value="ingredient"
                        :checked="ingredientTypes[key] === 'ingredient'"
                      />
                      Bahan
                    </div>
                  </div>
                </div>
                <div class="flex w-full items-center gap-2">
                  <div class="flex w-4/12 flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="active"
                      >
                        Resep
                        <span v-if="ingredientTypes[key] === 'ingredient'">
                          Bahan
                        </span>
                        <span v-else> Produk </span>
                      </label>
                    </div>
                    <div class="flex">
                      <template v-if="ingredientTypes[key] === 'ingredient'">
                        <Multiselect
                          v-model="ingredients[key]"
                          value="ingredient_id"
                          label="ingredient_name"
                          track-by="ingredient_name"
                          :placeholder="$t('formLabel.select.ingredient')"
                          :min-chars="0"
                          :resolve-on-load="true"
                          :infinite="true"
                          :limit="filterData.take"
                          :delay="1000"
                          :options="
                            async (query) => {
                              return await searchIngredientOption(query)
                            }
                          "
                          :searchable="true"
                          :can-clear="false"
                          :classes="multiSelectClasses"
                          @open="
                            (select$) => {
                              if (select$.noOptions) {
                                select$.resolveOptions()
                              }
                            }
                          "
                        />
                      </template>
                      <template v-else>
                        <Multiselect
                          v-model="ingredients[key]"
                          value="product_id"
                          label="product_name"
                          track-by="product_name"
                          :placeholder="$t('formLabel.select.product')"
                          :min-chars="0"
                          :resolve-on-load="true"
                          :infinite="true"
                          :limit="filterData.take"
                          :delay="1000"
                          :options="
                            async (query) => {
                              return await searchProductOption(
                                query,
                                ingredients
                              )
                            }
                          "
                          :searchable="true"
                          :can-clear="false"
                          :classes="multiSelectClasses"
                          @open="
                            (select$) => {
                              if (select$.noOptions) {
                                select$.resolveOptions()
                              }
                            }
                          "
                        />
                      </template>
                    </div>
                  </div>
                  <div class="flex w-4/12 flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="active"
                      >
                        Jumlah
                      </label>
                    </div>
                    <div class="flex">
                      <input
                        v-model="ingredientTotals[key]"
                        required="required"
                        class="w-full appearance-none rounded border-2 p-2 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :disabled="
                          ingredients[key] === undefined ||
                          ingredients[key] === null
                        "
                        :class="[
                          ingredients[key] === undefined ||
                          ingredients[key] === null
                            ? 'cursor-not-allowed bg-gray-200'
                            : 'cursor-pointer focus:border-primary focus:bg-white',
                        ]"
                        type="number"
                        min="0"
                        placeholder="Jumlah"
                      />
                    </div>
                  </div>
                  <div
                    v-if="ingredientTypes[key] === 'ingredient'"
                    class="flex w-3/12 flex-col space-y-2"
                  >
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="active"
                      >
                        Satuan
                      </label>
                    </div>
                    <div class="flex w-full">
                      <!-- {{ ingredients[key] }} -->
                      <select
                        v-model="ingredientUnits[key]"
                        class="w-full appearance-none rounded border-2 p-2 leading-tight text-gray-700 focus:outline-none focus:ring-0"
                        :disabled="
                          isEdit
                            ? false
                            : ingredients[key] === undefined ||
                              ingredients[key] === null
                        "
                        :class="[
                          ingredients[key] === undefined ||
                          ingredients[key] === null
                            ? 'cursor-not-allowed bg-gray-200'
                            : 'cursor-pointer focus:border-primary focus:bg-white',
                        ]"
                      >
                        <template
                          v-if="
                            getMasterUnitIngredient(ingredients[key]) === null
                          "
                        >
                          <option
                            v-for="unit in unitData.filter(
                              (item) => item.base === null
                            )"
                            :key="unit.id"
                            :value="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </template>
                        <template v-else>
                          <option
                            v-for="unit in unitData.filter(
                              (item) =>
                                getMasterUnitIngredient(ingredients[key]) ===
                                item.base
                            )"
                            :key="unit.id"
                            :value="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <template v-else>
                    <input v-model="ingredientUnits[key]" type="hidden" />
                  </template>

                  <div class="flex w-1/12 flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="active"
                      >
                        &nbsp;
                      </label>
                    </div>
                    <div class="flex">
                      <TrachIcon
                        v-show="
                          productIngredientTotal - ingredientDeletedKey.length >
                          1
                        "
                        class="cursor-pointer"
                        @click="deleteIngredientProduct(key)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <p
              class="cursor-pointer pb-4 text-left text-gray-700 hover:text-gray-900"
              @click="productIngredientTotal += 1"
            >
              + Tambah komponen baru
            </p>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2 text-left">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="product-time"
                  >
                    Waktu Produksi
                  </label>
                </div>
                <div class="flex w-1/2 items-center space-x-4">
                  <Field
                    v-slot="{ field }"
                    v-model="productTimeStart"
                    name="productTimeStart"
                  >
                    <input
                      id="product-time-start"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-center leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.productTimeStart !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                    />
                  </Field>
                  <p>-</p>

                  <Field
                    v-slot="{ field }"
                    v-model="productTimeEnd"
                    name="productTimeEnd"
                  >
                    <input
                      id="product-time-end"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-center leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.productTimeEnd !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                    />
                  </Field>
                  <p>{{ $t('info.timeMinute') }}</p>
                </div>
                <span class="text-xs text-red-400">
                  <template v-if="errors.productTimeStart !== undefined">
                    {{ $t(errors.productTimeStart) }}
                  </template>
                  <template v-else-if="errors.productTimeEnd !== undefined">
                    {{ $t(errors.productTimeEnd) }}
                  </template>
                </span>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="product-price"
                  >
                    Harga Produk *
                  </label>
                </div>
                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="productPrice"
                    name="productPrice"
                  >
                    <input
                      id="product-price"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.productPrice !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      placeholder="Harga Produk"
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.productPrice !== undefined">
                      {{ $t(errors.productPrice) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="product-category"
                  >
                    Kategori *
                  </label>
                </div>
                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="productCategory"
                    name="productCategory"
                  >
                    <select
                      id="product-category"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.productCategory !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      placeholder="Kategori Produk"
                    >
                      <option
                        v-for="category in categoryData"
                        :key="category.id"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.productCategory !== undefined">
                      {{ $t(errors.productCategory) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-2 px-5 pb-8">
            <button type="button" class="btn w-24" @click="closeModal">
              {{ $t('button.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary w-24">
              {{ $t('button.save') }}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add Product -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import * as Yup from 'yup'
import debounce from 'lodash.debounce'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import productMixin from '@/mixins/product.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

import DeleteModal from '@/components/DeleteModal.vue'
import Multiselect from '@vueform/multiselect'

import BookIcon from '@/assets/svg/menu/book.svg'
import TrachIcon from '@/assets/svg/trash.svg'

const digitsOnly = (value) => /^\d+$/.test(value)

export default {
  name: 'ProductListPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    Currency,
    ButtonComponent,

    DeleteModal,
    Multiselect,

    BookIcon,
    TrachIcon,
  },
  mixins: [globalMixin, tenantMixin, productMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    productListHeader: [
      {
        item: 'page.product.productList.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.product.productList.tableHeaders.stock',
        customClass: 'text-center',
      },
      {
        item: 'page.product.productList.tableHeaders.price',
        customClass: '',
      },
      {
        item: 'page.product.productList.tableHeaders.ingredients',
        customClass: 'text-center',
      },
      {
        item: 'page.product.productList.tableHeaders.availability',
        customClass: '',
      },
      {
        item: 'action.action',
        customClass: '',
      },
    ],
    productDetailListHeader: [
      {
        item: 'page.product.productList.detail.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.product.productList.detail.tableHeaders.stock',
        customClass: '',
      },
      {
        item: 'page.product.productList.detail.tableHeaders.type',
        customClass: '',
      },
      {
        item: 'page.product.productList.detail.tableHeaders.ingredients',
        customClass: '',
      },
    ],
    searchIngredient: '',

    showAddProductModal: false,

    attachments: [],
    deletedAttachmentIds: [],
    productImages: [],
    productId: '',
    productName: '',
    productDescription: '',
    productIngredientTotal: 1,
    ingredientDeletedKey: [],
    ingredientTypes: [],
    ingredients: [],
    ingredientTotals: [],
    ingredientUnits: [],
    productTimeStart: 1,
    productTimeEnd: 2,
    productPrice: 0,
    productCategory: '',

    // Form Validation
    productSchema: Yup.object().shape({
      productName: Yup.string()
        .typeError('formError.productName.required')
        .required('formError.productName.required')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      productCategory: Yup.string()
        .typeError('formError.category.required')
        .required('formError.category.required'),
      productPrice: Yup.string()
        .test('Digits only', 'formError.onlyNumber', digitsOnly)
        .matches(/^(?!\s+$).*/, 'formError.cannotOnlySpace'),
      productTimeStart: Yup.number()
        .typeError('formError.productTimeStart.required')
        .required('formError.productTimeStart.required')
        .integer()
        .lessThan(Yup.ref('productTimeEnd'), 'formError.productTimeStart.less'),
      productTimeEnd: Yup.number()
        .typeError('formError.productTimeEnd.required')
        .required('formError.productTimeEnd.required')
        .integer()
        .moreThan(
          Yup.ref('productTimeStart'),
          'formError.productTimeStart.less'
        ),
    }),
  }),
  watch: {
    searchIngredient: debounce(function (newVal, oldVal) {
      if (newVal.length === 0) {
        this.searchingProduct('productChild', 'local', '')
      } else if (newVal !== oldVal && newVal.length >= 2) {
        this.searchingProduct('productChild', 'local', this.searchIngredient)
      }
    }, 500),
  },
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getProductRelatedData()
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getProductRelatedData()
        })
        this.isInstitute = true
      }
      this.getAllUnit(false)
    }
  },
  methods: {
    getProductRelatedData() {
      this.getAllProduct(false, true, true, '', true)
      this.getAllIngredient(false, false, 50)
      this.getAllCategory()
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllProduct(true, false, false, '', true)
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getProductRelatedData()
    },
    getProductDetail(id) {
      this.productId = id
      this.searchIngredient = ''
      this.getProductDetailAPI()
    },
    editProduct(data) {
      this.showAddProductModal = true
      this.isEdit = true
      this.productId = data.id
      this.productName = data.name
      this.productDescription = data.description
      this.productPrice = data.price
      this.productImages = data.images
      this.productCategory = data.category.id
      this.attachments = []

      this.productIngredientTotal = data.childs.length
      data.childs.forEach((item, key) => {
        if (item.type === 'ingredient') {
          this.ingredientDataSearch.push({
            ingredient_id: item.ingredient_product_id,
            ingredient_name: item.name,
            value: item.ingredient_product_id,
            label: item.name,
          })
          this.ingredientDataSearchOption.push({
            ingredient_id: item.ingredient_product_id,
            ingredient_name: item.name,
            value: item.ingredient_product_id,
            label: item.name,
          })
        } else if (item.type === 'product') {
          this.productDataSearchOption.push({
            product_name: item.name,
            product_id: item.child_product_id,
            label: item.name,
            value: item.child_product_id,
          })
          this.productDataSearch.push({
            product_name: item.name,
            product_id: item.child_product_id,
            label: item.name,
            value: item.child_product_id,
          })
        }

        this.ingredientTypes[key] = item.type
        this.ingredients[key] =
          item.type === 'ingredient'
            ? item.ingredient_product_id
            : item.child_product_id
        this.ingredientTotals[key] = item.quantity.total
        this.ingredientUnits[key] =
          'unit' in item.quantity
            ? this.getUnitIdBy(item.quantity.unit, 'code')
            : null
      })
    },
    addProductModal() {
      this.showAddProductModal = true
      this.isEdit = false
      this.resetData(false)
    },
    updateProductAvailability($event, id) {
      this.updateProductAvailabilityAPI({
        id: id,
        available: $event.target.checked,
      })
    },
    setPrimary(type = 'attachment', key) {
      this.attachments.map((attachment) => {
        attachment.is_primary = false
      })
      this.productImages.forEach((attachment) => {
        attachment.is_primary = false
      })

      if (type === 'attachment') {
        this.attachments[key].is_primary = true
      } else {
        this.productImages[key].is_primary = true
      }
    },
    deleteAttachment(type = 'attachment', key, fileId = null) {
      if (type === 'attachment') {
        const attachment = this.attachments.at(key)
        if (attachment !== undefined) {
          this.attachments.splice(key, 1)
        }
      } else if (type === 'productImages') {
        const attachment = this.productImages.at(key)
        if (attachment !== undefined) {
          this.productImages.splice(key, 1)
        }
        if (fileId !== null) {
          this.deletedAttachmentIds.push(fileId)
        }
      } else {
        return
      }
    },
    saveProduct() {
      if (this.productName === '' || this.isLoadingSave) return

      const params = {}
      params.tenant_id = this.tenantId
      params.name = this.productName
      params.description = this.productDescription
      params.price = parseInt(this.productPrice)
      params.serving_time_start = parseInt(this.productTimeStart)
      params.serving_time_end = parseInt(this.productTimeEnd)
      params.serving_time_unit = 'minutes'
      params.category_id = this.productCategory

      const child_products = []
      const ingredient_products = []

      this.ingredients.forEach((item, key) => {
        if (!this.ingredientDeletedKey.includes(key)) {
          if (this.ingredientTypes[key] === 'ingredient') {
            ingredient_products.push({
              ingredient_product_id: item,
              quantity: this.ingredientTotals[key],
              unit_id: this.ingredientUnits[key],
            })
          } else {
            child_products.push({
              product_id: item,
              quantity: this.ingredientTotals[key],
            })
          }
        }
      })

      params.child_products = child_products
      params.ingredient_products = ingredient_products

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.productId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.productId

        this.saveProductAPI(params, true, false).then(() => {
          let promises = []
          this.attachments.forEach((attachment) => {
            promises.push(
              this.uploadImageProductAPI({
                image: attachment.file,
                is_primary: attachment.is_primary,
                id: this.productId,
              })
            )
          })
          this.deletedAttachmentIds.forEach((attachmentId) => {
            promises.push(
              this.deleteProductImageAPI({
                id: attachmentId,
              })
            )
          })
          this.productImages.forEach((productImage) => {
            if (productImage.is_primary) {
              promises.push(
                this.updateProductImageAPI({
                  id: productImage.id,
                })
              )
            }
          })
          Promise.all(promises).then(() => {
            this.resetData()
            this.productId = params.id
            this.getProductDetailAPI()
          })
        })
      } else {
        this.saveProductAPI(params, false, this.attachments.length === 0).then(
          () => {
            let promises = []
            if (!this.checkEmptyObject(this.createdData)) {
              const productId = this.createdData.id
              this.attachments.forEach((attachment) => {
                promises.push(
                  this.uploadImageProductAPI({
                    image: attachment.file,
                    is_primary: attachment.is_primary,
                    id: productId,
                  })
                )
              })
              Promise.all(promises).then(() => {
                this.resetData()
              })
            }
          }
        )
      }
    },
    fileInput(event) {
      const isPrimary = this.attachments.length === 0
      this.attachments.push({
        is_primary: isPrimary,
        file: event.target.files[0],
      })
    },
    closeModal() {
      this.showAddProductModal = false
    },
    confirmDelete(id) {
      this.deleteProductAPI(id)
    },
    deleteIngredientProduct(key) {
      this.ingredientDeletedKey.push(key)
      // this.productIngredientSubVariantTotal -= 1
    },
    resetData(afterSave = true) {
      this.productId = null
      this.productName = ''
      this.productDescription = ''
      this.productPrice = 0
      this.attachments = []
      this.deletedAttachmentIds = []
      this.productImages = []
      this.productIngredientTotal = 1
      this.ingredientTypes = []
      this.ingredients = []
      this.ingredientTotals = []
      this.ingredientUnits = []
      this.productCategory = ''
      this.ingredientDeletedKey = []
      this.searchIngredient = ''
      this.productTimeStart = 1
      this.productTimeEnd = 2

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            productName: '',
            productCategory: '',
            productPrice: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddProductModal = false
        this.isEdit = false
        this.getAllProduct(false, false, false, '', true)
      }
    },
  },
}
</script>
