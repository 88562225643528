import axios from 'axios'

const axiosSCM = axios.create({
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': import.meta.env.VITE_SCM_API_KEY,
    'x-language-code': import.meta.env.VITE_LANGUAGE_CODE,
  },
})

const tokenType =
  localStorage.getItem('inkanteenDashboard.tokenType') || 'Bearer'
const accessToken =
  localStorage.getItem('inkanteenDashboard.accessToken') || null
const refreshToken =
  localStorage.getItem('inkanteenDashboard.refreshToken') || null

let userActive = null
const activeUser = localStorage.getItem('inkanteenDashboard.activeUser') || null
if (activeUser !== null) {
  userActive = JSON.parse(activeUser)
  if ('role' in userActive.role) {
    const role = userActive.role.role
    axiosSCM.defaults.params = {}

    if (role === 'Institute Admin' && userActive.institute) {
      axiosSCM.defaults.params['institute_id'] = userActive.institute.id
    }
    if (role === 'Tenant Admin' && userActive.tenant) {
      axiosSCM.defaults.params['tenant_id'] = userActive.tenant.id
    }
  }
}

if (accessToken !== null) {
  axiosSCM.defaults.headers.common[
    'Authorization'
  ] = `${tokenType} ${accessToken}`
} else {
  delete axiosSCM.defaults.headers.common['Authorization']
}

if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging'
) {
  axiosSCM.defaults.baseURL = import.meta.env.VITE_SCM_API_URL
} else {
  // axiosSCM.defaults.baseURL = 'https://api.pinia.app'
}

export function routePathAPISCM() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return '/v1'
  } else {
    return '/api/v1'
  }
}

// axiosSCM.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { response, config } = error

//     // Temporary hide due to refresh token in API
//     if (response.status === 401 && false) {
//       return axios
//         .post(
//           `${routePathAPISCM()}/auth/token/refresh`,
//           { refresh_token: refreshToken },
//           {
//             ...config,
//           }
//         )
//         .then((response) => {
//           const statusCode = response.data.statusCode
//           if (statusCode === 200) {
//             const token = response.data.data

//             // SET NEW TOKEN
//             localStorage.setItem(
//               'inkanteenDashboard.accessToken',
//               token.access_token
//             )
//             // APPEND TOKEN TO CURRENT HEADER BEARER
//             axiosSCM.defaults.headers.common[
//               'Authorization'
//             ] = `${tokenType} ${token.access_token}`

//             // RETRYING REQUEST
//             return axiosSCM(config)
//           } else {
//             return Promise.reject(response)
//           }
//         })
//         .catch(() => {
//           return Promise.reject(error)
//         })
//     }
//     return Promise.reject(error)
//   }
// )

export default axiosSCM
