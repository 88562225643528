<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="flex flex-col-reverse justify-end border-b-2 py-3 md:flex-row md:justify-between md:py-5"
  >
    <!-- BEGIN: Breadcrumb -->
    <!-- <nav aria-label="breadcrumb" class="-intro-x mr-auto hidden sm:flex">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">{{ breadcumbName }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ 'title' in $route.meta ? $t($route.meta.title) : '' }}
        </li>
      </ol>
    </nav> -->
    <!-- END: Breadcrumb -->
    <div
      class="mt-2 hidden w-full flex-row justify-end self-end md:mt-0 md:flex md:justify-start"
    >
      <div
        v-for="menu in menusComputed"
        :key="menu.id"
        class="flex cursor-pointer items-center justify-center border-b-4 px-2 pb-2"
        :class="activeMenu === menu.id ? 'border-primary' : 'border-gray-200'"
        @click="changeActiveMenu(menu.id)"
      >
        <p class="text-center text-sm">{{ $t(menu.name) }}</p>
      </div>
    </div>

    <div class="flex w-full justify-end">
      <div
        v-if="
          showUserInfo &&
          'role' in activeUser &&
          activeUser.role.role === 'Super Tenant Admin'
        "
        class="w-full pr-4"
      >
        <Multiselect
          v-if="
            'role' in activeUser.role &&
            activeUser.role.role === 'Super Tenant Admin'
          "
          v-model="selectedTenantId"
          mode="tags"
          value="tenant_id"
          label="tenant_name"
          track-by="tenant_name"
          :placeholder="$t('formLabel.select.tenant')"
          :options="userTenantList"
          :searchable="true"
          :classes="multiSelectClasses"
        />
      </div>
      <div
        class="mr-6 flex items-center space-x-2 self-center border-r-2 border-gray-300 pr-4 md:mr-8 md:pr-8"
      >
        <div
          v-if="isCanStockPurchase"
          class="relative cursor-pointer"
          @click="
            $router.push({
              name: 'stock-purchase-cart',

              params: { slug: $route.params.slug },
              query: { lang: $route.query.lang },
            })
          "
        >
          <CartIcon />
          <div
            v-if="hasStockPurchaseCart"
            class="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500"
          >
            &nbsp;
          </div>
        </div>
        <p class="text-sm">{{ breadcumbName }}</p>
      </div>

      <!-- BEGIN: Notifications -->
      <!-- <Dropdown class="mr-6 flex self-center md:mr-8">
        <DropdownToggle
          tag="div"
          role="button"
          class="notification notification--bullet cursor-pointer"
        >
          <BellIcon class="notification__icon dark:text-slate-500" />
        </DropdownToggle>
        <DropdownMenu class="notification-content pt-2">
          <DropdownContent tag="div" class="notification-content__box">
            <div class="notification-content__title">Notifications</div>
            <div
              v-for="(faker, fakerKey) in $_.take($f(), 5)"
              :key="fakerKey"
              class="relative flex cursor-pointer items-center"
              :class="{ 'mt-5': fakerKey }"
            >
              <div class="image-fit mr-1 h-12 w-12 flex-none">
                <img
                  alt="InKanteen - Web Dashboard"
                  class="rounded-full"
                  :src="faker.photos[0]"
                />
                <div
                  class="absolute right-0 bottom-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600"
                ></div>
              </div>
              <div class="ml-2 overflow-hidden">
                <div class="flex items-center">
                  <a href="javascript:;" class="mr-5 truncate font-medium">{{
                    faker.users[0].name
                  }}</a>
                  <div class="ml-auto whitespace-nowrap text-xs text-slate-400">
                    {{ faker.times[0] }}
                  </div>
                </div>
                <div class="mt-0.5 w-full truncate text-slate-500">
                  {{ faker.news[0].shortContent }}
                </div>
              </div>
            </div>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown> -->
      <!-- END: Notifications -->
      <!-- BEGIN: Account Menu -->
      <Dropdown v-if="showUserInfo" class="flex self-center">
        <DropdownToggle class="flex">
          <div
            class="image-fit zoom-in h-8 w-8 overflow-hidden rounded-full shadow-lg md:w-9"
          >
            <ImageLoader
              :image="activeUser.avatar"
              :alt="activeUser.full_name"
              rounded-class="rounded-full"
            />
          </div>
          <div class="ml-3 hidden self-center md:block">
            <p class="text-sm">{{ activeUser.full_name }}</p>
          </div>
          <div class="ml-3 hidden self-center md:block">
            <ArrowDownIcon class="w-3" />
          </div>
        </DropdownToggle>
        <DropdownMenu class="w-56">
          <DropdownContent class="bg-primary text-white">
            <DropdownHeader tag="div" class="!font-normal">
              <div class="font-medium">{{ activeUser.full_name || null }}</div>
              <div class="mt-0.5 text-xs text-white/70 dark:text-slate-500">
                {{ activeUser.role.position || null }}
              </div>
            </DropdownHeader>

            <!-- <DropdownDivider class="border-white/[0.08]" />
            <DropdownItem
              class="hover:bg-white/5"
              @click="openURL('profile-user')"
            >
              <UserIcon class="mr-2 h-4 w-4" /> {{ $t('topBarMenu.profile') }}
            </DropdownItem>
            <DropdownItem
              class="hover:bg-white/5"
              @click="openURL('profile-bussiness')"
            >
              <SettingsIcon class="mr-2 h-4 w-4" /> {{ $t('topBarMenu.setting') }}
            </DropdownItem> -->

            <!-- <DropdownItem class="hover:bg-white/5">
              <SettingsIcon class="mr-2 h-4 w-4" /> {{ $t('topBarMenu.setting') }}
            </DropdownItem> -->
            <DropdownDivider class="border-white/[0.08]" />
            <DropdownItem class="hover:bg-white/5" @click="showLogoutModal">
              <LogOutIcon class="mr-2 h-4 w-4" /> {{ $t('action.logout') }}
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <Dropdown v-else class="flex self-center">
        <DropdownToggle class="flex">
          <div
            class="image-fit zoom-in h-8 w-8 overflow-hidden rounded-full shadow-lg"
          >
            <ImageLoader
              :image="activeUser.avatar"
              :alt="activeUser.full_name"
              rounded-class="rounded-full"
            />
          </div>
          <div class="ml-3 hidden self-center md:block">
            <p class="text-sm">{{ activeUser.full_name }}</p>
          </div>
          <div class="ml-3 hidden self-center md:block">
            <ArrowDownIcon class="w-3" />
          </div>
        </DropdownToggle>
        <DropdownMenu class="w-56">
          <DropdownContent class="bg-primary text-white">
            <DropdownItem class="hover:bg-white/5" @click="showLogoutModal">
              <LogOutIcon class="mr-2 h-4 w-4" /> {{ $t('action.logout') }}
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <!-- END: Account Menu -->
    </div>
  </div>
  <Modal :show="logoutModal" @hidden="logoutModal = false">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="my-6 text-xl">{{ $t('confirmation.logout') }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-primary mr-2 w-24"
          @click="logoutModal = false"
        >
          {{ $t('button.cancel') }}
        </button>
        <button type="button" class="btn btn-danger w-24" @click="logout">
          {{ $t('button.logout') }}
        </button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Top Bar -->
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import Multiselect from '@vueform/multiselect'

// import BellIcon from '@/assets/svg/bell.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'
import CartIcon from '@/assets/svg/cart.svg'

export default {
  components: {
    ImageLoader,
    Multiselect,

    // BellIcon,
    ArrowDownIcon,
    CartIcon,
  },
  mixins: [globalMixin],
  setup() {
    const store = useStore()

    const searchDropdown = ref(false)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }
    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      store,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
    }
  },
  data() {
    return {
      showUserInfo: false,
      breadcumbName: '',
      menus: [
        {
          id: 'sales',
          name: 'topBarMenu.sale',
        },
        {
          id: 'finance',
          name: 'topBarMenu.finance',
        },
        {
          id: 'setting',
          name: 'topBarMenu.setting',
        },
      ],
    }
  },
  computed: {
    activeMenu: {
      get() {
        return this.$store.getters['menu/activeMenu']
      },
      set(val) {
        return this.$store.dispatch('menu/activeMenu', val)
      },
    },
    menusComputed() {
      if (this.isCanStockPurchase) {
        const menu = this.menus
        menu.push({
          id: 'purchase_order',
          name: 'topBarMenu.purchaseOrder',
        })
        return menu
      } else if (this.isInstituteSuperAdminMode) {
        return [
          {
            id: 'sales',
            name: 'topBarMenu.sale',
          },
        ]
      } else {
        return this.menus
      }
    },
  },
  mounted() {
    if (this.isLoggedIn === false) {
      this.$router.push({
        name: 'login',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    } else {
      this.activeUser = this.store.getters['auth/activeUser']

      if (this.activeUser) {
        if (this.activeUser.tenant !== undefined) {
          this.breadcumbName = this.activeUser.tenant.name
        } else if (this.activeUser.institute !== undefined) {
          this.breadcumbName = this.activeUser.institute.name
        } else {
          this.breadcumbName = this.appName
        }
        if (
          this.activeUser.full_name !== null &&
          this.activeUser.full_name !== '' &&
          this.activeUser.role
        ) {
          this.showUserInfo = true
        }
      } else {
        this.activeUser = {
          avatar: null,
          full_name: 'User',
        }
        this.showUserInfo = false
      }
      // this.getLocalUserTenant()
      this.checkNeedRefreshToken()
    }
  },
  methods: {
    // changeSelectedTenantId($event) {
    //   console.log($event)
    //   this.selectedTenantId = $event
    // },
    showLogoutModal() {
      this.logoutModal = true
    },
    changeActiveMenu(active) {
      this.activeMenu = active
      // this.store.dispatch('menu/activeMenu', active)
      // this.activeMenu = this.store.getters['menu/activeMenu']
      // $router.push({
      //   name: 'sales',
      //   params: { slug: $route.params.slug },
      //   query: { lang: $route.query.lang },
      // })
    },

    openURL(pageName) {
      this.$router.push({
        name: pageName,
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
