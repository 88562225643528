// import {
//   setLocalStorageWithExpiry,
//   getLocalStorageWithExpiry,
// } from '../../plugins/basic-function'

// initial state
const state = () => ({
  isAuthenticated: false,
  user: {
    fullName: null,
    instituteId: null,
    instituteName: '',
    roleId: '',
    roleName: '',
  },
  filterDates: {
    start: '',
    end: '',
  },
})

// getters
const getters = {
  headerMode(state) {
    return state.headerMode
  },
  headerData(state) {
    return state.headerData
  },
  filterDates(state) {
    if (state.filterDates.start !== '' && filterDates.end !== '')
      return state.filterDates
    else if (
      localStorage.getItem('inkanteenDashboard.filterDates') !== null &&
      localStorage.getItem('inkanteenDashboard.filterDates') !== undefined
    ) {
      return JSON.parse(localStorage.getItem('inkanteenDashboard.filterDates'))
    } else {
      return null
    }
  },
}

// actions
const actions = {}

// mutations
const mutations = {
  setHeaderMode(state, val) {
    state.headerMode = val
  },
  setHeaderData(state, val) {
    state.headerData = val
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
