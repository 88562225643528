<template>
  <div
    class="flex w-full flex-col pb-5 dark:text-white md:pb-10 lg:w-2/6 lg:pb-0"
    :class="additionalClass"
  >
    <div
      class="relative flex w-full justify-between bg-slate-100 px-4 py-2 dark:bg-slate-800"
    >
      <h1 class="text-base font-semibold uppercase">
        {{ title }}
      </h1>
      <p
        v-if="tooltipHeader !== ''"
        class="cursor-help self-center rounded-full border border-gray-300 px-1.5 text-xs text-gray-400 hover:border-gray-500 hover:text-gray-500"
        @mouseover="showTooltip = true"
        @mouseleave="showTooltip = false"
        @click="showTooltip = !showTooltip"
      >
        <span>i</span>
        <!-- <span v-else>x</span> -->
      </p>
      <span
        v-if="showTooltip"
        class="absolute mt-8 w-full bg-white px-4 py-3 text-center text-xs shadow-lg"
      >
        {{ tooltipHeader }}
      </span>
    </div>
    <div class="w-full flex-1 px-4 py-6 capitalize">
      <slot></slot>
    </div>
    <div
      class="relative flex w-full justify-between bg-slate-100 px-4 py-2 dark:bg-slate-800"
    >
      <div v-if="showTotal" class="flex space-x-2">
        <h1 class="text-base font-semibold uppercase">
          {{ $t('page.report.summary.total') }}
        </h1>
        <p
          v-if="tooltipFooter !== ''"
          class="cursor-help self-center rounded-full border border-gray-300 px-1.5 text-xs text-gray-400 hover:border-gray-500 hover:text-gray-500"
          @mouseover="showTooltipFooter = true"
          @mouseleave="showTooltipFooter = false"
          @click="showTooltipFooter = !showTooltipFooter"
        >
          <span>i</span>
          <!-- <span v-else>x</span> -->
        </p>
      </div>

      <Currency
        v-if="showTotalCurrency"
        :total-in-string="total"
        class="text-base font-semibold"
      />
      <span
        v-if="showTooltipFooter"
        class="absolute mt-8 w-full bg-white p-3 text-center text-xs shadow-lg"
      >
        {{ tooltipFooter }}
      </span>
    </div>
  </div>
</template>
<script>
import Currency from '@/components/Currency.vue'
export default {
  name: 'SummaryItemComponent',
  components: {
    Currency,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    additionalClass: {
      type: String,
      required: false,
      default: '',
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    showTotalCurrency: {
      type: Boolean,
      default: true,
    },
    tooltipHeader: {
      type: String,
      default: '',
    },
    tooltipFooter: {
      type: String,
      default: '',
    },
    total: {
      type: [String, Number],
      default: '0',
    },
  },
  data: () => ({
    showTooltip: false,
    showTooltipFooter: false,
  }),
}
</script>
