<template>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div
            class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
          >
            <div class="flex justify-between align-middle">
              <div class="flex">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex self-center align-middle">
                  <h2 class="mr-5 truncate text-lg font-medium">
                    {{ $t('page.dashboard.sale') }}
                  </h2>
                </div>
              </div>
              <div class="flex">
                <div class="flex self-center align-middle">
                  <p class="text-sm">{{ dateFilterFormat(dateFilter) }}</p>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-end gap-4">
              <div class="flex w-full md:w-fit">
                <div
                  class="relative w-full text-slate-500 sm:ml-auto sm:mt-0 md:w-fit"
                >
                  <CalendarIcon
                    class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
                  />
                  <Litepicker
                    v-model="dateFilter"
                    :options="{
                      autoApply: false,
                      singleMode: false,
                      numberOfColumns: 2,
                      numberOfMonths: 2,
                      showWeekNumbers: true,
                      format: 'YYYY-MM-DD',
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                      },
                    }"
                    class="form-control box pl-8 md:w-56"
                  />
                </div>
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="mt-8 grid grid-cols-12 gap-6 md:mt-5">
            <Card
              :title="$t('page.dashboard.sale')"
              :is-currency="true"
              :is-up="cardData.sales.is_up"
              :percentage="cardData.sales.percent"
              :show-percentage="true"
              :total="String(cardData.sales.total)"
              wrapper-class="col-span-12 sm:col-span-6 2xl:col-span-3"
            />
            <Card
              :title="$t('page.dashboard.grossProfit')"
              :is-currency="true"
              :is-up="cardData.gross_profit.is_up"
              :percentage="cardData.gross_profit.percent"
              :show-percentage="true"
              :total="String(cardData.gross_profit.total)"
              wrapper-class="col-span-12 sm:col-span-6 2xl:col-span-3"
            />
            <Card
              :title="$t('page.dashboard.netProfit')"
              :is-currency="true"
              :is-up="cardData.net_profit.is_up"
              :percentage="cardData.net_profit.percent"
              :show-percentage="true"
              :total="String(cardData.net_profit.total)"
              wrapper-class="col-span-12 sm:col-span-6 2xl:col-span-3"
            />
            <Card
              :title="$t('page.dashboard.transactionTotal')"
              :is-currency="false"
              :is-up="cardData.transaction.is_up"
              :percentage="cardData.transaction.percent"
              :show-percentage="true"
              :total="String(cardData.transaction.total)"
              wrapper-class="col-span-12 sm:col-span-6 2xl:col-span-3"
            />
          </div>
        </div>
        <!-- END: General Report -->
        <div class="col-span-12 mt-8 items-end">
          <div class="flex w-full justify-center">
            <FilterTimeRange
              class="w-full md:w-fit"
              :active="periodicFilter"
              :options="[
                'daily',
                'yesterday',
                'weekly',
                'last_week',
                'monthly',
                'last_month',
                'quarterly',
                'last_quarter',
                'yearly',
                'last_year',
              ]"
              @change-filter="changeFilterTimeRange($event)"
            />
          </div>
        </div>
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 mt-0 lg:col-span-12">
          <div class="intro-y box p-5">
            <div
              class="flex w-full flex-col justify-between md:flex-row md:items-center"
            >
              <div class="flex self-center align-middle">
                <h2 class="mr-3 truncate text-lg font-medium">
                  {{ $t('page.dashboard.saleChart') }}
                </h2>
                <span class="flex self-center text-xs uppercase text-gray-500">
                  {{ $t(`formLabel.dropdown.timeRange.${periodicFilter}`) }}
                </span>
              </div>
            </div>
            <div>
              <ReportLineChart
                :height="275"
                item-name="Total Sales"
                :label="chart_sales_label"
                :data="chart_sales_values"
                class="mt-6 -mb-6"
              />
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Daily Top Product -->
        <div class="col-span-12 mt-0 md:mt-4 lg:col-span-6">
          <div class="intro-y box mt-5 p-5">
            <div class="flex w-full space-x-2 md:space-x-0">
              <div class="flex justify-center self-center align-middle">
                <TrollyIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div
                class="flex w-full flex-col space-y-1 md:flex-row md:space-y-0"
              >
                <div class="flex align-middle md:self-center">
                  <p class="mr-5 truncate text-base md:text-lg">
                    {{ $t('page.dashboard.bestSellingMenu') }}
                  </p>
                </div>

                <div class="flex w-full self-center md:justify-end">
                  <p class="text-xs uppercase md:text-sm">
                    {{ $t(`formLabel.dropdown.timeRange.${periodicFilter}`) }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="top_selling.length > 0" class="mt-8 sm:w-auto">
              <div
                v-for="(item, index) in top_selling"
                :key="item.code"
                class="flex items-center"
                :class="[index !== 0 ? 'pt-6' : '']"
              >
                <span class="truncate">{{ item.name }}</span>
                <span class="ml-auto font-medium">{{ item.total }}</span>
              </div>
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Daily Top Product -->
        <!-- BEGIN: Daily Least Product -->
        <div class="col-span-12 mt-0 md:mt-4 lg:col-span-6">
          <div class="intro-y box mt-5 p-5">
            <div class="flex w-full space-x-2 md:space-x-0">
              <div class="flex justify-center self-center align-middle">
                <TrollyIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div
                class="flex w-full flex-col space-y-1 md:flex-row md:space-y-0"
              >
                <div class="flex align-middle md:self-center">
                  <p class="mr-5 truncate text-sm md:text-lg">
                    {{ $t('page.dashboard.leastSellingMenu') }}
                  </p>
                </div>

                <div class="flex w-full self-center md:justify-end">
                  <p class="text-xs uppercase md:text-sm">
                    {{ $t(`formLabel.dropdown.timeRange.${periodicFilter}`) }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="least_selling.length > 0" class="mt-8 sm:w-auto">
              <div
                v-for="(item, index) in least_selling"
                :key="item.code"
                class="flex items-center"
                :class="[index !== 0 ? 'pt-6' : '']"
              >
                <span class="truncate">{{ item.name }}</span>
                <span class="ml-auto font-medium">{{ item.total }}</span>
              </div>
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Daily Least Product -->
        <!-- BEGIN: Peak Hour Chart -->
        <div class="col-span-12 mt-0 md:mt-4 lg:col-span-6">
          <div class="intro-y box mt-5 p-5">
            <div class="flex w-full space-x-2 md:space-x-0">
              <div class="flex justify-center self-center align-middle">
                <TrollyIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div
                class="flex w-full flex-col space-y-1 md:flex-row md:space-y-0"
              >
                <div class="flex align-middle md:self-center">
                  <p class="mr-5 truncate text-base md:text-lg">
                    {{ $t('page.dashboard.peakHour') }}
                  </p>
                </div>

                <div class="flex w-full self-center md:justify-end">
                  <p class="text-xs uppercase md:text-sm">
                    {{ $t(`formLabel.dropdown.timeRange.${periodicFilter}`) }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="!chart_peak_hour_empty">
              <ReportLineChart
                :height="275"
                item-name="Total Transaction"
                :label="chart_peak_hour_label"
                :data="chart_peak_hour_values"
                class="mt-6 -mb-6"
              />
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Peak Hour Chart -->
        <!-- BEGIN: Day of Week Sales Chart -->
        <div class="col-span-12 mt-0 md:mt-4 lg:col-span-6">
          <div class="intro-y box mt-5 p-5">
            <div class="flex w-full space-x-2 md:space-x-0">
              <div class="flex justify-center self-center align-middle">
                <TrollyIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div
                class="flex w-full flex-col space-y-1 md:flex-row md:space-y-0"
              >
                <div class="flex align-middle md:self-center">
                  <p class="truncate text-base md:mr-5 md:text-lg">
                    {{ $t('page.dashboard.daySales') }}
                  </p>
                </div>

                <div class="flex w-full self-center md:justify-end">
                  <p class="text-xs uppercase md:text-sm">
                    {{ $t(`formLabel.dropdown.timeRange.${periodicFilter}`) }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="!chart_sales_empty">
              <ReportLineChart
                :height="275"
                item-name="Total Sales"
                :label="chart_sales_label"
                :data="chart_sales_values"
                chart-type="bar"
                class="mt-6 -mb-6"
              />
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Day of Week Sales Chart -->
        <!-- BEGIN: Daily Payment Method Report -->
        <div v-if="false" class="col-span-12 mt-0 md:mt-4 lg:col-span-6">
          <div class="intro-y box mt-5 p-5">
            <div class="flex w-full">
              <div class="flex justify-center self-center align-middle">
                <TimeReverseIcon
                  class="mr-2 h-4 w-4 text-black dark:text-white"
                />
              </div>
              <div class="flex w-full">
                <div class="flex self-center align-middle">
                  <p class="mr-5 truncate text-base md:text-lg">
                    {{ $t('page.dashboard.paymentMethod') }}
                  </p>
                </div>

                <div class="flex w-full justify-end self-center">
                  <p class="text-xs uppercase md:text-sm">
                    {{ $t(`formLabel.dropdown.timeRange.${periodicFilter}`) }}
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="payment_method && Object.keys(payment_method).length !== 0"
              class="mt-8 sm:w-auto"
            >
              <div
                v-for="(item, key, index) in payment_method"
                :key="index"
                class="flex items-center"
                :class="[index !== 0 ? 'pt-6' : '']"
              >
                <span class="truncate uppercase">{{ key }}</span>
                <span class="ml-auto font-medium">
                  <Currency :total-in-string="String(item)" />
                </span>
              </div>
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Daily Payment Method Report -->
        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->
      </div>
    </div>
    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { ref } from 'vue'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import globalMixin from '@/mixins/global.js'

import Card from '@/components/Card.vue'

import Currency from '@/components/Currency.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import FilterTimeRange from '@/components/FilterTimeRange.vue'

import SaleIcon from '@/assets/svg/sale.svg'
import TrollyIcon from '@/assets/svg/trolly.svg'
import TimeReverseIcon from '@/assets/svg/time-reverse.svg'
import NoData from '@/components/NoData.vue'

export default {
  name: 'DashboardPage',
  components: {
    Card,
    Currency,
    ReportLineChart,
    FilterTimeRange,
    SaleIcon,
    TrollyIcon,
    TimeReverseIcon,
    NoData,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref('')
    const periodicFilter = ref('daily')

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      periodicFilter,
    }
  },
  data: () => ({
    cardData: {
      sales: {
        total: 0,
        percent: 0,
        is_up: false,
      },
      transaction: {
        total: 0,
        percent: 0,
        is_up: false,
      },
      gross_profit: {
        total: 0,
        percent: 0,
        is_up: false,
      },
      net_profit: {
        total: 0,
        percent: 0,
        is_up: false,
      },
    },
    top_selling: [],
    least_selling: [],
    payment_method: {},
    chart_sales_empty: [],
    chart_sales_label: [],
    chart_sales_values: [],
    chart_peak_hour_empty: [],
    chart_peak_hour_label: [],
    chart_peak_hour_values: [],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.initChangeDate()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.isInstituteSuperAdminMode) {
      this.$router.push({
        name: 'dashboard-super-institute',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    } else {
      this.initPeriodic()
    }
  },
  methods: {
    initChangeDate() {
      if (this.activeUserStore !== null) {
        this.getDashboardSummary()
      } else {
        this.toLoginPage()
      }
    },
    initPeriodic(isReloading = false) {
      this.getSalesChart(false, isReloading)
      this.getTopAndLeastSelling(false, isReloading)
      this.getPeakHourChart(false, isReloading)
    },
    async getDashboardSummary(showMessage = true, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('transaction/summary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          tenant_id: this.selectedTenantIdString,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.sales = responseData.sales_total
            this.cardData.transaction = responseData.transaction_total
            this.cardData.net_profit = responseData.net_profit
            this.cardData.gross_profit = responseData.gross_profit

            this.payment_method = responseData.payment_method
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getSalesChart(showMessage = true, setLoading = true) {
      if (setLoading) this.isLoading = true

      const params = {
        periodic: this.periodicFilter,
      }
      if (this.selectedTenantIdString !== '') {
        params.tenant_id = this.selectedTenantIdString
      }
      await this.$store
        .dispatch('transaction/salesChart', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            const sales_chart = responseData
            this.chart_sales_empty = sales_chart.length <= 0
            this.chart_sales_label = sales_chart.map((item) => item.name)
            this.chart_sales_values = sales_chart.map((item) => item.total)
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getTopAndLeastSelling(showMessage = true, setLoading = true) {
      if (setLoading) this.isLoading = true

      const params = {
        periodic: this.periodicFilter,
      }
      if (this.selectedTenantIdString !== '') {
        params.tenant_id = this.selectedTenantIdString
      }

      await this.$store
        .dispatch('transaction/topAndLeastSelling', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.top_selling = responseData.top_selling
            this.least_selling = responseData.least_selling
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getPeakHourChart(showMessage = true, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('transaction/peakHourChart', {
          periodic: this.periodicFilter,
          tenant_id: this.selectedTenantIdString,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            const peak_hour_chart = responseData
            this.chart_peak_hour_empty = peak_hour_chart.length <= 0
            this.chart_peak_hour_label = peak_hour_chart.map(
              (item) => item.name
            )
            this.chart_peak_hour_values = peak_hour_chart.map(
              (item) => item.total
            )
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.initPeriodic(true)
    },
  },
}
</script>
