<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Tenant Sales Line Chart -->
          <div class="col-span-12 mt-4">
            <div class="intro-y box py-5 px-1 md:mt-6 md:p-5">
              <div
                class="flex w-full flex-col justify-between md:flex-row md:items-center"
              >
                <div class="flex self-center align-middle">
                  <h2 class="mr-3 truncate text-base font-medium md:text-lg">
                    {{ $t('page.report.paymentMethod.title') }}
                  </h2>
                  <span
                    class="flex self-center text-xs uppercase text-gray-500"
                  >
                    {{ periodicFilter }}
                  </span>
                </div>
                <div class="mt-0 flex w-full items-center justify-center">
                  <FilterTimeRange
                    :active="periodicFilter"
                    @change-filter="changeFilterTimeRange($event)"
                  />
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!paymentMethodChartEmpty"
                  :label="paymentMethodChartLabel"
                  :item-name="paymentMethodChartItemName"
                  :data="paymentMethodChartData"
                  :show-legend="true"
                  :height="275"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
              <div
                class="flex w-full max-w-full flex-row overflow-x-scroll pt-10"
              >
                <ComparisonItem
                  v-for="(item, index) in comparisonItem"
                  :key="index"
                  :title="item.title.toUpperCase()"
                  :has-checkbox="true"
                  :show-color="true"
                  :value="item.value"
                  :is-checked="paymentIds.includes(item.value)"
                  :color="item.color !== '' ? item.color : 'rgb(192,192,192)'"
                  @change-filter="changeChartPaymentMethod($event)"
                />
              </div>
            </div>
          </div>
          <!-- END: Tenant Sales Line Chart -->
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Tenant Sales Report -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <TitleComponent
                  :title="$t($route.meta.title)"
                  :date-string="dateFilter"
                />
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-12 gap-6 md:mt-5">
              <Card
                :title="$t('page.report.paymentMethod.totalPayment')"
                :is-currency="true"
                :is-up="cardData.payment_total.is_up"
                :percentage="cardData.payment_total.percent"
                :total="cardData.payment_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-6"
              />
              <Card
                :title="$t('page.report.paymentMethod.totalTransaction')"
                :is-currency="false"
                :is-up="cardData.transaction_total.is_up"
                :percentage="cardData.transaction_total.percent"
                :total="cardData.transaction_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-6"
              />
            </div>
          </div>
          <!-- END: Tenant Sales Report -->
        </div>
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <div class="flex w-full justify-end pt-10 md:pt-20">
      <div class="flex w-full justify-end md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
        <ExportCSVComponent @export="downloadPaymentMethod" />
      </div>
    </div>
    <TableComponent
      :date-string="dateFilter"
      :title="$t('page.report.paymentMethod.paymentMethodTable')"
      :header="paymentMethodReportHeader"
      :is-show-search="false"
      :meta-data="metaData"
      :is-not-empty="paymentMethodReportData.length > 0"
      :filter="{
        title: 'Payment Method',
        items: ['all'].concat(paymentIds),
      }"
      @change-filter-dropdown="changeFilterDropdown($event)"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="data in paymentMethodReportData" :key="data.payment_id">
        <td class="uppercase">{{ data.payment_name }}</td>
        <td>{{ data.transaction }}</td>
        <td class="text-center">{{ data.transaction_percentage }}</td>
        <td><Currency :total-in-string="data.sales" /></td>
        <!-- <td class="text-center">{{ data.sales_percentage }}</td> -->
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import SaleIcon from '@/assets/svg/sale.svg'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'

import FilterTimeRange from '@/components/FilterTimeRange.vue'
import TableComponent from '@/components/TableComponent.vue'
import TitleComponent from '@/components/TitleComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import Card from '@/components/Card.vue'
import Currency from '@/components/Currency.vue'
import ComparisonItem from '@/components/ComparisonItem.vue'
import NoData from '@/components/NoData.vue'

export default {
  name: 'PaymentMethodPage',
  components: {
    Breadcrumb,
    ReportLineChart,
    SaleIcon,
    FilterTimeRange,
    TableComponent,
    TitleComponent,
    ExportCSVComponent,
    Card,
    Currency,
    ComparisonItem,
    NoData,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()
    const periodicFilter = ref('daily')
    const paymentIds = ref([
      'balance',
      'qris',
      'va',
      'ewallet',
      'cash',
      'brizzi',
      'edc',
    ])

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      periodicFilter,
      paymentIds,
    }
  },
  data: () => ({
    paymentMethodReportHeader: [
      {
        item: 'page.report.paymentMethod.tableHeaders.paymentMethod',
        customClass: '',
      },
      {
        item: 'page.report.paymentMethod.tableHeaders.totalTransaction',
        customClass: '',
      },
      {
        item: 'page.report.paymentMethod.tableHeaders.totalPercent',
        customClass: 'text-center',
      },
      {
        item: 'page.report.paymentMethod.tableHeaders.sales',
        customClass: '',
      },
      // {
      //   item: 'page.report.paymentMethod.tableHeaders.salesPercent',
      //   customClass: 'text-center',
      // },
    ],
    paymentMethodReportData: [],
    cardData: {
      payment_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      transaction_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
      payment_ids: '',
    },
    paymentMethodChartEmpty: true,
    paymentMethodChartLabel: [],
    paymentMethodChartItemName: [],
    paymentMethodChartData: [],
    comparisonItem: [
      {
        title: 'balance',
        value: 'balance',
        color: '',
      },
      {
        title: 'qris',
        value: 'qris',
        color: '',
      },
      {
        title: 'va',
        value: 'va',
        color: '',
      },
      {
        title: 'ewallet',
        value: 'ewallet',
        color: '',
      },
      {
        title: 'cash',
        value: 'cash',
        color: '',
      },
      {
        title: 'brizzi',
        value: 'brizzi',
        color: '',
      },
      {
        title: 'edc',
        value: 'edc',
        color: '',
      },
    ],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    paymentId() {
      return this.paymentIds.toString()
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.init()
      this.getPaymentMethodChart()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }
  },
  methods: {
    init() {
      this.getSummary()
      this.filterData.payment_ids = this.paymentId
      this.getPaymentMethodReport()
    },
    async getSummary() {
      this.isLoading = true

      await this.$store
        .dispatch('payment/paymentMethodSummary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.sales_total = responseData.sales_total
            this.cardData.payment_total = responseData.payment_total
            this.cardData.transaction_total = responseData.transaction_total
          }

          this.message = response.data.message
          this.showToast('success')
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          this.showToast('failed')
        })
    },
    async getPaymentMethodReport() {
      await this.store
        .dispatch('payment/paymentMethodReport', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          page: this.filterData.page,
          take: this.filterData.take,
          payment_name: this.filterData.search,
          payment_ids: this.filterData.payment_ids,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.paymentMethodReportData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async getPaymentMethodChart() {
      await this.store
        .dispatch('payment/paymentMethodChart', {
          periodic: this.periodicFilter,
          payment_ids: this.paymentId,
        })
        .then((response) => {
          const responseData = response.data
          if (responseData.data) {
            const filteredChart = responseData.data.reduce((filtered, item) => {
              let key = item.payment_id
              if (!filtered[key]) {
                filtered[key] = null
              }
              filtered[key] = item.charts
              return filtered
            }, {})

            this.paymentMethodChartEmpty = true
            this.paymentMethodChartItemName = []
            this.paymentMethodChartData = []
            this.comparisonItem.forEach((item) => {
              if (this.paymentIds.includes(item.value)) {
                item.color = this.getColor(this.paymentIds.indexOf(item.value))
              } else {
                item.color = ''
              }
            })
            this.paymentIds.forEach((item, index) => {
              this.paymentMethodChartItemName.push(item.toUpperCase())
              let labels = []
              let values = []
              if (filteredChart[item] !== undefined) {
                this.paymentMethodChartEmpty = false

                filteredChart[item].forEach((item) => {
                  labels.push(item.name)
                  values.push(item.total)
                })
              }
              if (index === 0) {
                this.paymentMethodChartLabel = labels
              }
              this.paymentMethodChartData.push(values)
            })
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async downloadPaymentMethod(fileType) {
      await this.store
        .dispatch('payment/paymentMethodDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'resport_payment_method.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.getPaymentMethodChart()
    },
    changeFilterDropdown(event) {
      if (event === 'all') {
        if (this.filterData.payment_ids === this.paymentIds) return
        this.filterData.payment_ids = this.paymentId
      } else {
        if (event === this.filterData.payment_ids) return
        this.filterData.payment_ids = event
      }
      this.getPaymentMethodReport()
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getPaymentMethodReport()
    },
    changeChartPaymentMethod(event) {
      if (event.status) {
        this.paymentIds.push(event.item)
      } else {
        this.paymentIds = this.paymentIds.filter((item) => item != event.item)
      }
      this.getPaymentMethodChart()
    },
  },
}
</script>
