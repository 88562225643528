<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.report.index')"
        :subtitles="[
          {
            title: $t('menu.report.summary'),
            link: 'report-summary',
          },
          {
            title: 'title' in $route.meta ? $t($route.meta.title) : '',
            link: null,
          },
        ]"
      />
    </div>
    <div class="flex w-full justify-end md:w-fit">
      <div class="relative w-full text-slate-500 sm:ml-auto">
        <CalendarIcon
          class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
        />
        <Litepicker
          v-model="dateFilter"
          :options="{
            autoApply: false,
            singleMode: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            showWeekNumbers: true,
            format: 'YYYY-MM-DD',
            dropdowns: {
              minYear: 1990,
              maxYear: null,
              months: true,
              years: true,
            },
          }"
          class="form-control box pl-8 sm:w-56"
        />
      </div>
      <ExportCSVComponent v-if="false" @export="downloadSummary" />
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 md:mt-4">
          <div class="intro-y box mt-5 p-5">
            <div
              class="flex flex-col md:flex-row md:items-center md:justify-between"
            >
              <div class="flex">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex">
                  <div
                    class="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:self-center md:align-middle"
                  >
                    <h2 class="truncate text-lg font-medium">
                      {{ $t($route.meta.title) }}
                    </h2>
                    <span class="flex text-xs text-gray-500 md:self-center">
                      {{ dateFilterFormat(dateFilter) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col gap-3 pt-5 md:pt-10">
              <div class="flex">
                <div
                  class="flex w-full flex-col pb-5 dark:text-white md:pb-10 lg:w-2/6 lg:pb-0"
                >
                  &nbsp;
                </div>
                <div class="flex w-full max-w-3/4 overflow-x-scroll">
                  <div
                    v-for="i in totalSelectedTenant"
                    :key="i"
                    class="flex w-full flex-shrink-0 flex-col items-start justify-start gap-4 px-6 pb-5 dark:text-white md:pb-10 lg:w-2/6 lg:pb-0"
                  >
                    <Multiselect
                      :id="`inputTenant-${i}`"
                      v-model="tenantIds[i]"
                      :options="
                        tenantOptions.filter(
                          (item) => !selectedTenantIds.includes(item.value)
                        )
                      "
                      class="w-full border-none shadow outline-none ring-0"
                      :searchable="true"
                      :classes="multiSelectClasses"
                      :close-on-select="true"
                      :can-clear="true"
                      :hide-selected="false"
                      :placeholder="$t('formInput.filterTenant')"
                      @change="changeTenantIds($event, i)"
                    >
                      <template #option="{ option, isSelected }">
                        <p class="flex w-full items-center justify-between">
                          <span>{{ option.label }}</span>
                          <span
                            v-if="isSelected(option)"
                            class="text-lg text-gray-500"
                          >
                            &times;
                          </span>
                        </p>
                      </template>
                    </Multiselect>
                    <div
                      v-if="selectedTenants[i] === undefined"
                      class="flex w-full cursor-pointer flex-col items-center justify-center gap-2 border-gray-500 text-center text-gray-500 hover:border-gray-600 hover:text-gray-600"
                      @click="focusInput(`inputTenant-${i}`)"
                    >
                      <div
                        class="w-2/12 rounded-lg border-2 text-center text-2xl"
                      >
                        +
                      </div>
                      <p>
                        {{
                          $t(
                            'page.report.summary.comparison.selectTenantToCompare'
                          )
                        }}
                      </p>
                    </div>
                    <div v-else class="w-full text-center">
                      <ImageLoader
                        :image="
                          selectedTenants[i].image !== ''
                            ? selectedTenants[i]
                            : null
                        "
                        :alt="selectedTenants[i].name"
                        height="h-20"
                        custom-class="w-20"
                        class="flex w-full justify-center"
                      />
                      <p class="text-base font-semibold">
                        {{ selectedTenants[i].name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- BEGIN: Income -->
              <div class="flex">
                <SummaryItem
                  :title="$t('page.report.summary.income.title')"
                  :show-total-currency="false"
                >
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.grossSales') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.serviceCharge') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.instituteFee') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.rounding') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.tax') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.other') }}</p>
                  </div>
                </SummaryItem>
                <div class="flex w-full max-w-3/4 overflow-x-scroll">
                  <SummaryItem
                    v-for="i in totalSelectedTenant"
                    :key="i"
                    :title="
                      selectedTenants[i] === undefined
                        ? $t('page.report.summary.comparison.selectTenant')
                        : selectedTenants[i].name
                    "
                    :show-total="false"
                    :total="incomes[i] !== undefined ? incomes[i].net_sales : 0"
                    additional-class="flex-shrink-0"
                  >
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          incomes[i] !== undefined ? incomes[i].gross_sales : 0
                        "
                      />
                    </div>
                    <div v-if="false" class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          incomes[i] !== undefined ? incomes[i].net_sales : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          incomes[i] !== undefined
                            ? incomes[i].service_charge
                            : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          incomes[i] !== undefined
                            ? incomes[i].institute_fee
                            : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          incomes[i] !== undefined ? incomes[i].tax_fee : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          incomes[i] !== undefined ? incomes[i].tax_fee : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          incomes[i] !== undefined ? incomes[i].tax_fee : 0
                        "
                      />
                    </div>
                  </SummaryItem>
                </div>
              </div>
              <!-- END: Income -->

              <!-- BEGIN: Promo Cost -->
              <div class="flex">
                <SummaryItem
                  :title="$t('page.report.summary.promoCost.title')"
                  :show-total-currency="false"
                >
                  <div class="flex justify-between py-2">
                    <p class="font-bold">
                      {{ $t('page.report.summary.promoCost.nonComplement') }}
                    </p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.promoCost.promo') }}</p>
                  </div>
                  <div class="flex justify-between py-2 pl-6">
                    <ul class="w-full list-outside list-disc">
                      <li>
                        <p>
                          {{
                            $t(
                              'page.report.summary.promoCost.coveredByMerchant'
                            )
                          }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="flex justify-between py-2 pl-6">
                    <ul class="w-full list-outside list-disc">
                      <li>
                        <p>
                          {{
                            $t(
                              'page.report.summary.promoCost.coveredByInkanteen'
                            )
                          }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="divider py-3"></div>
                  <div class="flex justify-between py-2">
                    <p class="font-bold">
                      {{ $t('page.report.summary.promoCost.complement') }}
                    </p>
                  </div>
                  <div class="flex justify-between py-2 pl-6">
                    <ul class="w-full list-outside list-disc">
                      <li>
                        <p>
                          {{ $t('page.report.summary.promoCost.totalProduct') }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="flex justify-between py-2 pl-6">
                    <ul class="w-full list-outside list-disc">
                      <li>
                        <p>
                          {{
                            $t('page.report.summary.promoCost.totalQuantity')
                          }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="flex justify-between py-2 pl-6">
                    <ul class="w-full list-outside list-disc">
                      <li>
                        <p>
                          {{
                            $t('page.report.summary.promoCost.totalProductCost')
                          }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </SummaryItem>
                <div class="flex w-full max-w-3/4 overflow-x-scroll">
                  <SummaryItem
                    v-for="i in totalSelectedTenant"
                    :key="i"
                    :title="
                      selectedTenants[i] === undefined
                        ? $t('page.report.summary.comparison.selectTenant')
                        : selectedTenants[i].name
                    "
                    :total="promos[i] !== undefined ? promos[i].total : 0"
                    :show-total="false"
                    additional-class="flex-shrink-0"
                  >
                    <div class="flex justify-between py-2">&nbsp;</div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          promos[i] !== undefined
                            ? promos[i].non_complement.cost_total
                            : 0
                        "
                        prefix-text="("
                        leading-text=")"
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          promos[i] !== undefined
                            ? promos[i].non_complement.covered_by_merchant
                            : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          promos[i] !== undefined
                            ? promos[i].non_complement.covered_by_inkanteen
                            : 0
                        "
                      />
                    </div>
                    <div class="divider py-3"></div>
                    <div class="flex justify-between py-2">&nbsp;</div>
                    <div class="flex justify-between py-2">
                      <div>
                        <p>
                          {{
                            promos[i] !== undefined
                              ? promos[i].complement.product_total
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="flex justify-between py-2">
                      <div>
                        <p>
                          {{
                            promos[i] !== undefined
                              ? promos[i].complement.quantity_total
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          promos[i] !== undefined
                            ? promos[i].complement.cost_total
                            : 0
                        "
                      />
                    </div>
                  </SummaryItem>
                </div>
              </div>
              <!-- END: Promo Cost -->

              <!-- BEGIN: Other -->
              <div class="flex">
                <SummaryItem
                  :title="$t('page.report.summary.other.title')"
                  :show-total-currency="false"
                >
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.other.adminFee') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.other.MDR') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.uniqueCode') }}</p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ $t('page.report.summary.income.rounding') }}</p>
                  </div>
                </SummaryItem>
                <div class="flex w-full max-w-3/4 overflow-x-scroll">
                  <SummaryItem
                    v-for="i in totalSelectedTenant"
                    :key="i"
                    :title="
                      selectedTenants[i] === undefined
                        ? $t('page.report.summary.comparison.selectTenant')
                        : selectedTenants[i].name
                    "
                    :total="others_total[i] !== undefined ? others_total[i] : 0"
                    :show-total="false"
                    additional-class="flex-shrink-0"
                  >
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          others[i] !== undefined ? others[i].admin_fee : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          others[i] !== undefined ? others[i].mdr_fee : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          others[i] !== undefined ? others[i].unique_code : 0
                        "
                      />
                    </div>
                    <div class="flex justify-between py-2">
                      <Currency
                        :total-in-string="
                          others[i] !== undefined ? others[i].round_price : 0
                        "
                      />
                    </div>
                  </SummaryItem>
                </div>
              </div>
              <!-- END: Gross Profit -->
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
      </div>
    </div>

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { ref } from 'vue'
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import Currency from '@/components/Currency.vue'
import SummaryItem from '@/components/SummaryItem.vue'
import Multiselect from '@vueform/multiselect'
import ImageLoader from '@/components/image-loader/Main.vue'

import ExportCSVComponent from '@/components/ExportCSVComponent.vue'

import SaleIcon from '@/assets/svg/sale.svg'

export default {
  name: 'SummaryCompareTenant',
  components: {
    Breadcrumb,
    Currency,
    SummaryItem,
    Multiselect,
    ImageLoader,
    ExportCSVComponent,

    SaleIcon,
  },
  mixins: [globalMixin, tenantMixin],
  setup() {
    const dateFilter = ref('')

    return {
      dateFilter,
    }
  },
  data: () => ({
    totalSelectedTenant: 3,
    selectedTenants: [],
    selectedTenantIds: [],

    promos: [],
    others: [],
    others_total: [],
    incomes: [],
    incomes_total: [],

    tenantIds: [],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.getSummaryPerTenant()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (!this.isInstituteSuperAdminMode) {
      this.toLoginPage()
    } else {
      this.getAllTenant()
    }
  },
  methods: {
    getSummaryPerTenant() {
      if (this.tenantIds.length > 0) {
        this.tenantIds.forEach((item, key) => {
          this.getSalesSummary(item, key)
        })
      }
    },
    focusInput(ref, isClose = false) {
      const element = document.getElementById(ref)
      const optionElement = document.getElementById(
        `${ref}-multiselect-options`
      )
      if (element !== undefined && element !== null) {
        element.setAttribute('aria-expanded', !isClose)
        if (optionElement !== undefined && optionElement !== null) {
          if (
            optionElement.parentElement !== undefined &&
            optionElement.parentElement !== null
          ) {
            if (isClose) {
              optionElement.parentElement.classList.add('is-hidden')
            } else {
              optionElement.parentElement.classList.remove('is-hidden')
            }
          }
        }
      }
    },
    async getSalesSummary(tenant_id, index = 0) {
      this.isLoading = true

      await this.$store
        .dispatch('sales/summary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          tenant_ids: tenant_id,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.others[index] = responseData.others
            this.promos[index] = responseData.promos
            this.incomes[index] = responseData.incomes
            this.others_total[index] = this.sumObjectValue(responseData.others)
            this.incomes_total[index] = this.sumObjectValue(
              responseData.incomes
            )
          }
          this.message = response.message
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          this.showToast('failed')
        })
    },
    async downloadSummary(fileType = '.csv') {
      await this.$store
        .dispatch('sales/summaryDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'summary_data.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    changeTenantIds(event, index) {
      this.focusInput(`inputTenant-${index}`, true)
      if (event !== null) {
        const findTenant = this.tenantData.find((item) => item.id === event)
        if (findTenant != undefined) {
          this.selectedTenants[index] = findTenant
          this.selectedTenantIds[index] = event
          this.getSalesSummary(event, index)

          if (this.tenantOptions.length > this.totalSelectedTenant)
            this.totalSelectedTenant += 1
        }
      } else {
        this.selectedTenants[index] = undefined
        this.selectedTenantIds[index] = null
        this.others[index] = undefined
        this.promos[index] = undefined
        this.incomes[index] = undefined
        this.others_total[index] = 0
        this.incomes_total[index] = 0

        if (this.totalSelectedTenant > 4) this.totalSelectedTenant -= 1
      }
    },
  },
}
</script>
