import axios from 'axios'
import { randomString } from './basic-function'

let baseURL = ''
if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging'
) {
  baseURL = import.meta.env.VITE_LUMEN_API_URL
}

const axiosLumen = axios.create({
  baseURL: baseURL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': import.meta.env.VITE_LUMEN_API_KEY,
    'x-language-code': import.meta.env.VITE_LANGUAGE_CODE,
  },
})

const deviceId = localStorage.getItem('inkanteenDashboard.deviceId') || null
if (deviceId === null) {
  const randomDeviceId = randomString(16, '#aA')
  localStorage.setItem('inkanteenDashboard.deviceId', randomDeviceId)
}
axiosLumen.defaults.headers.common['x-device-id'] = deviceId
axiosLumen.defaults.headers.common['x-geolocation'] =
  'location access is not allowed'

const tokenType =
  localStorage.getItem('inkanteenDashboard.tokenType') || 'Bearer'
const accessToken =
  localStorage.getItem('inkanteenDashboard.accessToken') || null
const refreshToken =
  localStorage.getItem('inkanteenDashboard.refreshToken') || null

let userActive = null
const activeUser = localStorage.getItem('inkanteenDashboard.activeUser') || null
if (activeUser !== null) {
  userActive = JSON.parse(activeUser)
  if ('role' in userActive.role) {
    const role = userActive.role.role
    axiosLumen.defaults.params = {}

    if (role === 'Institute Admin' && userActive.institute) {
      axiosLumen.defaults.params['institute_id'] = userActive.institute.id
    }
    if (role === 'Tenant Admin' && userActive.tenant) {
      axiosLumen.defaults.params['tenant_id'] = userActive.tenant.id
      // axiosLumen.defaults.params['tenant_id'] = '491c94e1-4b80-400b-bf7a-7ec0a4dca956'
    }
  }
}

if (accessToken !== null) {
  axiosLumen.defaults.headers.common[
    'Authorization'
  ] = `${tokenType} ${accessToken}`
} else {
  delete axiosLumen.defaults.headers.common['Authorization']
}

export function routePathAPILumen() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return `/${import.meta.env.VITE_LUMEN_API_VERSION}`
  } else {
    return `/api-lumen/${import.meta.env.VITE_LUMEN_API_VERSION}`
  }
  // {
  //   return `/${import.meta.env.VITE_LUMEN_API_VERSION}`
  // } else {
  //   return `/api/${import.meta.env.VITE_LUMEN_API_VERSION}`
  // }
}

// axiosLumen.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { response, config } = error

//     if (response.status === 401) {
//       return axios
//         .post(
//           `${routePathAPILumen()}/auth/token/refresh`,
//           { refresh_token: refreshToken },
//           {
//             ...config,
//           }
//         )
//         .then((response) => {
//           const statusCode = response.data.statusCode
//           if (statusCode === 200) {
//             const token = response.data.data

//             // SET NEW TOKEN
//             localStorage.setItem(
//               'inkanteenDashboard.accessToken',
//               token.access_token
//             )
//             // APPEND TOKEN TO CURRENT HEADER BEARER
//             axiosLumen.defaults.headers.common[
//               'Authorization'
//             ] = `${tokenType} ${token.access_token}`

//             // RETRYING REQUEST
//             return axiosLumen(config)
//           } else {
//             localStorage.removeItem('inkanteenDashboard.tokenType')
//             localStorage.removeItem('inkanteenDashboard.accessToken')
//             localStorage.removeItem('inkanteenDashboard.refreshToken')
//             window.location.href = '/'

//             return Promise.reject(response)
//           }
//         })
//         .catch(() => {
//           return Promise.reject(error)
//         })
//     }
//     return Promise.reject(error)
//   }
// )

export default axiosLumen
