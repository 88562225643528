import i18n from '../plugins/i18n.js'
import { createRouter, createWebHistory } from 'vue-router'
import { LANGUAGES } from '../constants'

import SideMenu from '../layouts/side-menu/Main.vue'

import VersionPage from '../views/Version.vue'
import ErrorPage from '../views/Error.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Register from '../views/Register.vue'
import Profile from '../views/setting/profile/Index.vue'
import Bussiness from '../views/setting/bussiness/Index.vue'

import Dashboard from '../views/Dashboard.vue'
import SuperInstituteDashboard from '../views/super-institute/Dashboard.vue'

import ReportSummary from '../views/sales/report/Summary.vue'
import ReportSummaryCompareTenant from '../views/sales/report/SummaryCompareTenant.vue'
import OrderDetail from '../views/sales/report/OrderDetail.vue'
import TenantSales from '../views/sales/report/TenantSales.vue'
import DailySales from '../views/sales/report/DailySales.vue'
import ProductSales from '../views/sales/report/ProductSales.vue'
import PaymentMethod from '../views/sales/report/PaymentMethod.vue'
import ReportWithdrawal from '../views/sales/report/Withdrawal.vue'
import NonSalesDetail from '../views/sales/report/NonSalesDetail.vue'
import AdditionalFeeReport from '../views/sales/report/AdditionalFee.vue'

import AverageTransaction from '../views/sales/analytic/AverageTransaction.vue'
import GrowthPercantage from '../views/sales/analytic/GrowthPercantage.vue'
import SatisfactionIndex from '../views/sales/analytic/SatisfactionIndex.vue'
import StockTurnover from '../views/sales/analytic/StockTurnover.vue'
import OmnichannelIndex from '../views/sales/analytic/OmnichannelIndex.vue'

import ComingSoon from '../views/misc/ComingSoon.vue'

// V2
import ProductList from '../views/sales/product/ProductList.vue'
import IngredientList from '../views/sales/product/IngredientList.vue'
import IngredientBrandList from '../views/sales/product/IngredientBrandList.vue'
import IngredientCategoryList from '../views/sales/product/IngredientCategoryList.vue'
import VariantList from '../views/sales/product/VariantList.vue'

import EmployeeList from '../views/setting/employee/List.vue'
import AddEmployee from '../views/setting/employee/Add.vue'
import AddPrivilege from '../views/setting/employee/AddPrivilege.vue'
import EmployeeAccessList from '../views/setting/employee/Access.vue'
import EmployeePrivilegeList from '../views/setting/employee/Privilege.vue'

import TableManagement from '../views/setting/TableManagement.vue'
import KiosKList from '../views/setting/kiosk/KiosKList.vue'
import CustomerList from '../views/setting/customer/CustomerList.vue'
import AdditionalFeeList from '../views/setting/payment/AdditionalFee.vue'
import OmnichannelList from '../views/setting/payment/OmniChannel.vue'
import PaymentChannelList from '../views/setting/payment/PaymentChannel.vue'
import ShiftHistory from '../views/setting/employee/ShiftHistory.vue'
import OfficeHour from '../views/setting/tenant/OfficeHour.vue'
import InventoryAdjustment from '../views/setting/inventory/InventoryAdjustment.vue'
import PantryList from '../views/setting/inventory/PantryList.vue'

import BalanceReport from '../views/finance/Balance.vue'

import TenantBillList from '../views/finance/income/TenantBillList.vue'

import RoutineBillingList from '../views/finance/expense/RoutineBillingList.vue'
import ExpenseList from '../views/finance/expense/ExpenseList.vue'

import StockPurchase from '../views/purchase-order/StockPurchase.vue'
import HistoryPurchaseOrder from '../views/purchase-order/HistoryPurchaseOrder.vue'
import HistoryDetailPurchaseOrder from '../views/purchase-order/HistoryDetailPurchaseOrder.vue'
import StockPurchaseCart from '../views/purchase-order/StockPurchaseCart.vue'
import StockPurchaseCartAddress from '../views/purchase-order/StockPurchaseCartAddress.vue'
import StockPurchaseCartAddressForm from '../views/purchase-order/StockPurchaseCartAddressForm.vue'
import StockPurchaseSupplier from '../views/purchase-order/StockPurchaseSupplier.vue'
import StockPurchaseSupplierDetail from '../views/purchase-order/StockPurchaseSupplierDetail.vue'
import StockPurchaseProductDetail from '../views/purchase-order/StockPurchaseProductDetail.vue'

import RetailUnitList from '../views/retail/UnitList.vue'
import RetailCategoryList from '../views/retail/CategoryList.vue'
import RetailBrandList from '../views/retail/BrandList.vue'
import RetailProductList from '../views/retail/ProductList.vue'
import RetailSimplePurchasingStock from '../views/retail/SimplePurchasingStock.vue'
import RetailStockAdjustment from '../views/retail/StockAdjustment.vue'
import RetailSupplierList from '../views/retail/SupplierList.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/version',
    name: 'version',
    component: VersionPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage,
  },
  {
    path: '/',
    component: SideMenu,
    name: 'sales',
    // props: { activeMenu: 'sales' },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      // REPORT MENU
      {
        path: 'report/summary',
        name: 'report-summary',
        component: ReportSummary,
        meta: {
          title: 'menu.report.summary',
        },
      },
      {
        path: 'report/summary/compare-tenant',
        name: 'report-summary-compare-tenant',
        component: ReportSummaryCompareTenant,
        meta: {
          title: 'menu.report.compareTenant',
        },
      },
      {
        path: 'report/order-detail',
        name: 'report-order-detail',
        component: OrderDetail,
        meta: {
          title: 'menu.report.orderDetail',
        },
      },
      {
        path: 'report/non-sales-detail',
        name: 'report-non-sales-detail',
        component: NonSalesDetail,
        meta: {
          title: 'menu.report.nonSalesDetail',
        },
      },
      {
        path: 'report/tenant-sales',
        name: 'report-tenant-sales',
        component: TenantSales,
        meta: {
          title: 'menu.report.tenantSales',
        },
      },
      {
        path: 'report/daily-sales',
        name: 'report-daily-sales',
        component: DailySales,
        meta: {
          title: 'menu.report.dailySales',
        },
      },
      {
        path: 'report/product-sales',
        name: 'report-product-sales',
        // component: ComingSoon,
        component: ProductSales,
        meta: {
          title: 'menu.report.productSales',
        },
      },
      {
        path: 'report/payment-method',
        name: 'report-payment-method',
        component: PaymentMethod,
        meta: {
          title: 'menu.report.paymentMethod',
        },
      },
      {
        path: 'report/withdrawal',
        name: 'report-withdrawal',
        component: ReportWithdrawal,
        meta: {
          title: 'menu.report.withdrawal',
        },
      },
      {
        path: 'report/promotion',
        name: 'report-promotion',
        // component: Blank,
        component: ComingSoon,
        meta: {
          title: 'menu.report.promotion',
        },
      },
      {
        path: 'report/additional-fee',
        name: 'report-additional-fee',
        component: AdditionalFeeReport,
        meta: {
          title: 'menu.report.additionalFee',
        },
      },
      // SHIFT HISTORY
      {
        path: 'report/shift-history',
        name: 'shift-history',
        component: ShiftHistory,
        meta: {
          title: 'menu.setting.shift',
        },
      },
      // ANALYTIC MENU
      {
        path: 'analytic/average-transaction',
        name: 'analytic-average-transaction',
        component: AverageTransaction,
        meta: {
          title: 'menu.analytic.averageTransaction',
        },
      },
      {
        path: 'analytic/growth-percentage',
        name: 'analytic-growth-percentage',
        component: GrowthPercantage,
        meta: {
          title: 'menu.analytic.growthPercentage',
        },
      },
      {
        path: 'analytic/stock-turnover',
        name: 'analytic-stock-turnover',
        component: StockTurnover,
        meta: {
          title: 'menu.analytic.stockTurnOver',
        },
      },
      {
        path: 'analytic/omnichannel-index',
        name: 'analytic-omnichannel-index',
        component: OmnichannelIndex,
        meta: {
          title: 'menu.analytic.omnichannelIndex',
        },
      },
      {
        path: 'analytic/satisfaction-index',
        name: 'analytic-satisfaction-index',
        component: SatisfactionIndex,
        meta: {
          title: 'menu.analytic.satisfactionIndex',
        },
      },
      // Product Menu
      {
        path: 'product/list',
        name: 'product-list',
        component: ProductList,
        meta: {
          title: 'menu.product.productList',
        },
      },
      {
        path: 'ingredient/list',
        name: 'ingredient-list',
        component: IngredientList,
        meta: {
          title: 'menu.product.ingredients',
        },
      },
      {
        path: 'ingredient/brand/list',
        name: 'ingredient-brand-list',
        component: IngredientBrandList,
        meta: {
          title: 'menu.product.ingredientBrands',
        },
      },
      {
        path: 'ingredient/category/list',
        name: 'ingredient-category-list',
        component: IngredientCategoryList,
        meta: {
          title: 'menu.product.ingredientCategories',
        },
      },
      {
        path: 'variant/list',
        name: 'variant-list',
        component: VariantList,
        meta: {
          title: 'menu.product.variant',
        },
      },
    ],
  },
  {
    path: '/finance',
    component: SideMenu,
    name: 'finance',
    // props: { activeMenu: 'finance' },
    children: [
      {
        path: '',
        name: 'dashboard-finance',
        component: Dashboard,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      // Balance
      {
        path: 'balance',
        name: 'balance',
        component: BalanceReport,
        meta: {
          title: 'menu.balance.index',
        },
      },

      // Cashbook
      {
        path: 'cashbook',
        name: 'cashbook',
        component: Dashboard,
        meta: {
          title: 'menu.cashbook.index',
        },
      },
      {
        path: 'cashbook-bank-list',
        name: 'cashbook-bank-list',
        // component: CashbookBankList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.bankList',
        },
      },
      {
        path: 'cashbook-bank-list/:id',
        name: 'detail-cashbook-bank-list',
        // component: DetailCashbookBankList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.bankList',
        },
      },
      {
        path: 'add-cashbook-bank-list',
        name: 'add-cashbook-bank-list',
        // component: AddCashbookBankList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.bankList',
        },
      },
      {
        path: 'transfer-list',
        name: 'transfer-list',
        // component: TransferList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.transferList',
        },
      },
      {
        path: 'add-transfer-list',
        name: 'add-transfer-list',
        // component: AddTransferList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.transferList',
        },
      },
      {
        path: 'account-list',
        name: 'account-list',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.accountList',
        },
      },
      {
        path: 'account-list/:id',
        name: 'detail-account-list',
        // component: DetailAccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.bankList',
        },
      },
      {
        path: 'add-account-list',
        name: 'add-account-list',
        // component: AddAccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.cashbook.bankList',
        },
      },
      // Income
      {
        path: 'income-list',
        name: 'income-list',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.income.incomeList',
        },
      },
      {
        path: 'income-reconciliation',
        name: 'income-reconciliation',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.income.incomeReconciliation',
        },
      },
      // {
      //   path: 'tenant-bill-list',
      //   name: 'tenant-bill-list',
      //   component: TenantBillList,
      //   meta: {
      //     title: 'menu.income.tenantBillList',
      //   },
      // },
      // Expense
      // {
      //   path: 'expense-list',
      //   name: 'expense-list',
      //   component: ExpenseList,
      //   meta: {
      //     title: 'menu.expense.expenseList',
      //   },
      // },
      // {
      //   path: 'routine-billing-list',
      //   name: 'routine-billing-list',
      //   component: RoutineBillingList,
      //   meta: {
      //     title: 'menu.expense.routineBillingList',
      //   },
      // },
      {
        path: 'payment-list',
        name: 'payment-list',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.expense.paymentList',
        },
      },
      {
        path: 'cost-list',
        name: 'cost-list',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.expense.costList',
        },
      },
      {
        path: 'partner-list',
        name: 'partner-list',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.expense.partnerList',
        },
      },
      // Finance Report
      {
        path: 'balance-sheet',
        name: 'balance-sheet',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.financeReport.balanceSheet',
        },
      },
      {
        path: 'income-statement',
        name: 'income-statement',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.financeReport.incomeStatement',
        },
      },
      {
        path: 'cashflow-statement',
        name: 'cashflow-statement',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.financeReport.cashflowStatement',
        },
      },
      {
        path: 'debt-report',
        name: 'debt-report',
        // component: AccountList,
        component: ComingSoon,
        meta: {
          title: 'menu.financeReport.debtReport',
        },
      },
    ],
  },
  {
    path: '/setting',
    name: 'setting',
    component: SideMenu,
    // props: { activeMenu: 'setting' },
    children: [
      {
        path: '',
        name: 'dashboard-setting',
        component: Dashboard,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      {
        path: 'profile/user',
        name: 'profile-user',
        component: Profile,
        meta: {
          title: 'menu.profile.account',
        },
      },
      {
        path: 'profile/bussiness',
        name: 'profile-bussiness',
        component: Bussiness,
        meta: {
          title: 'menu.profile.bussiness',
        },
      },
      // Settings v2
      {
        path: 'employee',
        name: 'employee',
        component: EmployeeList,
        meta: {
          title: 'menu.employee.index',
        },
      },
      {
        path: 'add-employee',
        name: 'add-employee',
        component: AddEmployee,
        meta: {
          title: 'menu.employee.add',
        },
      },
      {
        path: 'employee-access',
        name: 'employee-access',
        component: EmployeeAccessList,
        meta: {
          title: 'menu.employee.access',
        },
      },
      {
        path: 'employee-privilege',
        name: 'employee-privilege',
        component: EmployeePrivilegeList,
        meta: {
          title: 'menu.employee.privilege',
        },
      },
      {
        path: 'add-privilege',
        name: 'add-privilege',
        component: AddPrivilege,
        meta: {
          title: 'menu.employee.addPrivilege',
        },
      },
      {
        path: 'table-management',
        name: 'table-management',
        component: TableManagement,
        meta: {
          title: 'menu.setting.tableManagement',
        },
      },
      {
        path: 'customer/list',
        name: 'customer-list',
        component: CustomerList,
        meta: {
          title: 'menu.setting.kiosk',
        },
      },
      {
        path: 'kiosk-management',
        name: 'kiosk-management',
        component: KiosKList,
        meta: {
          title: 'menu.setting.kiosk',
        },
      },
      {
        path: 'additional-fee',
        name: 'additional-fee',
        component: AdditionalFeeList,
        meta: {
          title: 'menu.setting.additionalFee',
        },
      },
      {
        path: 'omnichannel',
        name: 'omnichannel',
        component: OmnichannelList,
        meta: {
          title: 'menu.setting.omnichannel',
        },
      },
      {
        path: 'payment-channel',
        name: 'payment-channel',
        component: PaymentChannelList,
        meta: {
          title: 'menu.setting.paymentChannel',
        },
      },
      {
        path: 'office-hour',
        name: 'office-hour',
        component: OfficeHour,
        meta: {
          title: 'menu.setting.officeHour',
        },
      },
      {
        path: 'inventory/adjustment',
        name: 'inventory-adjustment',
        component: InventoryAdjustment,
        meta: {
          title: 'menu.setting.inventoryAdjustment',
        },
      },
      {
        path: 'inventory/pantry',
        name: 'inventory-pantry',
        component: PantryList,
        meta: {
          title: 'menu.setting.pantry',
        },
      },
    ],
  },
  {
    path: '/setting/retail',
    name: 'setting-retail',
    component: SideMenu,
    children: [
      {
        path: 'inventory/unit',
        name: 'retail-inventory-unit',
        component: RetailUnitList,
        meta: {
          title: 'menu.setting.unitList',
        },
      },
      {
        path: 'inventory/category',
        name: 'retail-inventory-category',
        component: RetailCategoryList,
        meta: {
          title: 'menu.setting.categoryList',
        },
      },
      {
        path: 'inventory/brand',
        name: 'retail-inventory-brand',
        component: RetailBrandList,
        meta: {
          title: 'menu.setting.brandList',
        },
      },
      {
        path: 'inventory/product',
        name: 'retail-inventory-product',
        component: RetailProductList,
        meta: {
          title: 'menu.setting.productList',
        },
      },
      {
        path: 'inventory/management/stock-adjustment',
        name: 'retail-inventory-management-stock-adjustment',
        component: RetailStockAdjustment,
        meta: {
          title: 'menu.setting.stockAdjustment',
        },
      },
      {
        path: 'inventory/management/stock-purchase',
        name: 'retail-inventory-management-stock-purchase',
        component: RetailSimplePurchasingStock,
        meta: {
          title: 'menu.setting.stockPurchase',
        },
      },
      {
        path: 'inventory/management/supplier',
        name: 'retail-inventory-management-supplier',
        component: RetailSupplierList,
        meta: {
          title: 'menu.setting.supplier',
        },
      },
    ],
  },
  {
    path: '/purchase-order',
    name: 'purchase-order',
    component: SideMenu,
    // props: { activeMenu: 'setting' },
    children: [
      {
        path: '',
        name: 'dashboard-purchase-order',
        component: Dashboard,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      {
        path: 'stock-purchase/history',
        name: 'stock-purchase-history',
        component: HistoryPurchaseOrder,
        meta: {
          title: 'menu.purchaseOrder.historyOrder',
        },
      },
      {
        path: 'stock-purchase/history/:orderId',
        name: 'stock-purchase-history-detail',
        component: HistoryDetailPurchaseOrder,
        meta: {
          title: 'menu.purchaseOrder.historyOrder',
        },
      },
      {
        path: 'stock-purchase',
        name: 'stock-purchase',
        component: StockPurchase,
        meta: {
          title: 'menu.purchaseOrder.stockPurchase',
        },
      },
      {
        path: 'stock-purchase/cart',
        name: 'stock-purchase-cart',
        component: StockPurchaseCart,
        meta: {
          title: 'menu.purchaseOrder.stockPurchase',
        },
      },
      {
        path: 'stock-purchase/cart/address',
        name: 'stock-purchase-cart-address',
        component: StockPurchaseCartAddress,
        meta: {
          title: 'menu.purchaseOrder.stockPurchase',
        },
      },
      {
        path: 'stock-purchase/cart/address/form',
        name: 'stock-purchase-cart-address-form',
        component: StockPurchaseCartAddressForm,
        meta: {
          title: 'menu.purchaseOrder.stockPurchase',
        },
      },
      {
        path: 'stock-purchase/supplier',
        name: 'stock-purchase-supplier',
        component: StockPurchaseSupplier,
        meta: {
          title: 'menu.purchaseOrder.stockPurchaseSupplier',
        },
      },
      {
        path: 'stock-purchase/supplier/:id',
        name: 'stock-purchase-supplier-detail',
        component: StockPurchaseSupplierDetail,
        meta: {
          title: 'menu.purchaseOrder.stockPurchaseSupplier',
        },
      },
      {
        path: 'stock-purchase/product/:id',
        name: 'stock-purchase-product-detail',
        component: StockPurchaseProductDetail,
        meta: {
          title: 'menu.purchaseOrder.stockPurchase',
        },
      },
    ],
  },
  {
    path: '/super-institute',
    name: 'super-institute',
    component: SideMenu,
    children: [
      {
        path: '',
        name: 'dashboard-super-institute',
        component: SuperInstituteDashboard,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
    ],
  },
  // {
  //   path: '/simple-menu',
  //   component: SimpleMenu,
  //   children: [
  //     {
  //       path: 'dashboard-overview-1',
  //       name: 'simple-menu-dashboard-overview-1',
  //       component: DashboardOverview1,
  //     },
  //     {
  //       path: 'dashboard-overview-2',
  //       name: 'simple-menu-dashboard-overview-2',
  //       component: DashboardOverview2,
  //     },
  //     {
  //       path: 'dashboard-overview-3',
  //       name: 'simple-menu-dashboard-overview-3',
  //       component: DashboardOverview3,
  //     },
  //     {
  //       path: 'dashboard-overview-4',
  //       name: 'simple-menu-dashboard-overview-4',
  //       component: DashboardOverview4,
  //     },
  //     {
  //       path: 'categories',
  //       name: 'simple-menu-categories',
  //       component: Categories,
  //     },
  //     {
  //       path: 'add-product',
  //       name: 'simple-menu-add-product',
  //       component: AddProduct,
  //     },
  //     {
  //       path: 'product-list',
  //       name: 'simple-menu-product-list',
  //       component: ProductList,
  //     },
  //     {
  //       path: 'product-grid',
  //       name: 'simple-menu-product-grid',
  //       component: ProductGrid,
  //     },
  //     {
  //       path: 'transaction-list',
  //       name: 'simple-menu-transaction-list',
  //       component: TransactionList,
  //     },
  //     {
  //       path: 'transaction-detail',
  //       name: 'simple-menu-transaction-detail',
  //       component: TransactionDetail,
  //     },
  //     {
  //       path: 'seller-list',
  //       name: 'simple-menu-seller-list',
  //       component: SellerList,
  //     },
  //     {
  //       path: 'seller-detail',
  //       name: 'simple-menu-seller-detail',
  //       component: SellerDetail,
  //     },
  //     {
  //       path: 'reviews',
  //       name: 'simple-menu-reviews',
  //       component: Reviews,
  //     },
  //     {
  //       path: 'inbox',
  //       name: 'simple-menu-inbox',
  //       component: Inbox,
  //     },
  //     {
  //       path: 'file-manager',
  //       name: 'simple-menu-file-manager',
  //       component: FileManager,
  //     },
  //     {
  //       path: 'point-of-sale',
  //       name: 'simple-menu-point-of-sale',
  //       component: PointOfSale,
  //     },
  //     {
  //       path: 'chat',
  //       name: 'simple-menu-chat',
  //       component: Chat,
  //     },
  //     {
  //       path: 'post',
  //       name: 'simple-menu-post',
  //       component: Post,
  //     },
  //     {
  //       path: 'calendar',
  //       name: 'simple-menu-calendar',
  //       component: Calendar,
  //     },
  //     {
  //       path: 'crud-data-list',
  //       name: 'simple-menu-crud-data-list',
  //       component: CrudDataList,
  //     },
  //     {
  //       path: 'crud-form',
  //       name: 'simple-menu-crud-form',
  //       component: CrudForm,
  //     },
  //     {
  //       path: 'users-layout-1',
  //       name: 'simple-menu-users-layout-1',
  //       component: UsersLayout1,
  //     },
  //     {
  //       path: 'users-layout-2',
  //       name: 'simple-menu-users-layout-2',
  //       component: UsersLayout2,
  //     },
  //     {
  //       path: 'users-layout-3',
  //       name: 'simple-menu-users-layout-3',
  //       component: UsersLayout3,
  //     },
  //     {
  //       path: 'profile-overview-1',
  //       name: 'simple-menu-profile-overview-1',
  //       component: ProfileOverview1,
  //     },
  //     {
  //       path: 'profile-overview-2',
  //       name: 'simple-menu-profile-overview-2',
  //       component: ProfileOverview2,
  //     },
  //     {
  //       path: 'profile-overview-3',
  //       name: 'simple-menu-profile-overview-3',
  //       component: ProfileOverview3,
  //     },
  //     {
  //       path: 'wizard-layout-1',
  //       name: 'simple-menu-wizard-layout-1',
  //       component: WizardLayout1,
  //     },
  //     {
  //       path: 'wizard-layout-2',
  //       name: 'simple-menu-wizard-layout-2',
  //       component: WizardLayout2,
  //     },
  //     {
  //       path: 'wizard-layout-3',
  //       name: 'simple-menu-wizard-layout-3',
  //       component: WizardLayout3,
  //     },
  //     {
  //       path: 'blog-layout-1',
  //       name: 'simple-menu-blog-layout-1',
  //       component: BlogLayout1,
  //     },
  //     {
  //       path: 'blog-layout-2',
  //       name: 'simple-menu-blog-layout-2',
  //       component: BlogLayout2,
  //     },
  //     {
  //       path: 'blog-layout-3',
  //       name: 'simple-menu-blog-layout-3',
  //       component: BlogLayout3,
  //     },
  //     {
  //       path: 'pricing-layout-1',
  //       name: 'simple-menu-pricing-layout-1',
  //       component: PricingLayout1,
  //     },
  //     {
  //       path: 'pricing-layout-2',
  //       name: 'simple-menu-pricing-layout-2',
  //       component: PricingLayout2,
  //     },
  //     {
  //       path: 'invoice-layout-1',
  //       name: 'simple-menu-invoice-layout-1',
  //       component: InvoiceLayout1,
  //     },
  //     {
  //       path: 'invoice-layout-2',
  //       name: 'simple-menu-invoice-layout-2',
  //       component: InvoiceLayout2,
  //     },
  //     {
  //       path: 'faq-layout-1',
  //       name: 'simple-menu-faq-layout-1',
  //       component: FaqLayout1,
  //     },
  //     {
  //       path: 'faq-layout-2',
  //       name: 'simple-menu-faq-layout-2',
  //       component: FaqLayout2,
  //     },
  //     {
  //       path: 'faq-layout-3',
  //       name: 'simple-menu-faq-layout-3',
  //       component: FaqLayout3,
  //     },
  //     {
  //       path: 'update-profile',
  //       name: 'simple-menu-update-profile',
  //       component: UpdateProfile,
  //     },
  //     {
  //       path: 'change-password',
  //       name: 'simple-menu-change-password',
  //       component: ChangePassword,
  //     },
  //     {
  //       path: 'regular-table',
  //       name: 'simple-menu-regular-table',
  //       component: RegularTable,
  //     },
  //     {
  //       path: 'tabulator',
  //       name: 'simple-menu-tabulator',
  //       component: Tabulator,
  //     },
  //     {
  //       path: 'modal',
  //       name: 'simple-menu-modal',
  //       component: Modal,
  //     },
  //     {
  //       path: 'slide-over',
  //       name: 'simple-menu-slide-over',
  //       component: SlideOver,
  //     },
  //     {
  //       path: 'notification',
  //       name: 'simple-menu-notification',
  //       component: Notification,
  //     },
  //     {
  //       path: 'tab',
  //       name: 'simple-menu-tab',
  //       component: Tab,
  //     },
  //     {
  //       path: 'accordion',
  //       name: 'simple-menu-accordion',
  //       component: Accordion,
  //     },
  //     {
  //       path: 'button',
  //       name: 'simple-menu-button',
  //       component: Button,
  //     },
  //     {
  //       path: 'alert',
  //       name: 'simple-menu-alert',
  //       component: Alert,
  //     },
  //     {
  //       path: 'progress-bar',
  //       name: 'simple-menu-progress-bar',
  //       component: ProgressBar,
  //     },
  //     {
  //       path: 'tooltip',
  //       name: 'simple-menu-tooltip',
  //       component: Tooltip,
  //     },
  //     {
  //       path: 'dropdown',
  //       name: 'simple-menu-dropdown',
  //       component: Dropdown,
  //     },
  //     {
  //       path: 'typography',
  //       name: 'simple-menu-typography',
  //       component: Typography,
  //     },
  //     {
  //       path: 'icon',
  //       name: 'simple-menu-icon',
  //       component: Icon,
  //     },
  //     {
  //       path: 'loading-icon',
  //       name: 'simple-menu-loading-icon',
  //       component: LoadingIcon,
  //     },
  //     {
  //       path: 'regular-form',
  //       name: 'simple-menu-regular-form',
  //       component: RegularForm,
  //     },
  //     {
  //       path: 'datepicker',
  //       name: 'simple-menu-datepicker',
  //       component: Datepicker,
  //     },
  //     {
  //       path: 'tom-select',
  //       name: 'simple-menu-tom-select',
  //       component: TomSelect,
  //     },
  //     {
  //       path: 'file-upload',
  //       name: 'simple-menu-file-upload',
  //       component: FileUpload,
  //     },
  //     {
  //       path: 'wysiwyg-editor',
  //       name: 'simple-menu-wysiwyg-editor',
  //       component: WysiwygEditor,
  //     },
  //     {
  //       path: 'validation',
  //       name: 'simple-menu-validation',
  //       component: Validation,
  //     },
  //     {
  //       path: 'chart',
  //       name: 'simple-menu-chart',
  //       component: Chart,
  //     },
  //     {
  //       path: 'slider',
  //       name: 'simple-menu-slider',
  //       component: Slider,
  //     },
  //     {
  //       path: 'image-zoom',
  //       name: 'simple-menu-image-zoom',
  //       component: ImageZoom,
  //     },
  //   ],
  // },
  // {
  //   path: '/top-menu',
  //   component: TopMenu,
  //   children: [
  //     {
  //       path: 'dashboard-overview-1',
  //       name: 'top-menu-dashboard-overview-1',
  //       component: DashboardOverview1,
  //     },
  //     {
  //       path: 'dashboard-overview-2',
  //       name: 'top-menu-dashboard-overview-2',
  //       component: DashboardOverview2,
  //     },
  //     {
  //       path: 'dashboard-overview-3',
  //       name: 'top-menu-dashboard-overview-3',
  //       component: DashboardOverview3,
  //     },
  //     {
  //       path: 'dashboard-overview-4',
  //       name: 'top-menu-dashboard-overview-4',
  //       component: DashboardOverview4,
  //     },
  //     {
  //       path: 'categories',
  //       name: 'top-menu-categories',
  //       component: Categories,
  //     },
  //     {
  //       path: 'add-product',
  //       name: 'top-menu-add-product',
  //       component: AddProduct,
  //     },
  //     {
  //       path: 'product-list',
  //       name: 'top-menu-product-list',
  //       component: ProductList,
  //     },
  //     {
  //       path: 'product-grid',
  //       name: 'top-menu-product-grid',
  //       component: ProductGrid,
  //     },
  //     {
  //       path: 'transaction-list',
  //       name: 'top-menu-transaction-list',
  //       component: TransactionList,
  //     },
  //     {
  //       path: 'transaction-detail',
  //       name: 'top-menu-transaction-detail',
  //       component: TransactionDetail,
  //     },
  //     {
  //       path: 'seller-list',
  //       name: 'top-menu-seller-list',
  //       component: SellerList,
  //     },
  //     {
  //       path: 'seller-detail',
  //       name: 'top-menu-seller-detail',
  //       component: SellerDetail,
  //     },
  //     {
  //       path: 'reviews',
  //       name: 'top-menu-reviews',
  //       component: Reviews,
  //     },
  //     {
  //       path: 'inbox',
  //       name: 'top-menu-inbox',
  //       component: Inbox,
  //     },
  //     {
  //       path: 'file-manager',
  //       name: 'top-menu-file-manager',
  //       component: FileManager,
  //     },
  //     {
  //       path: 'point-of-sale',
  //       name: 'top-menu-point-of-sale',
  //       component: PointOfSale,
  //     },
  //     {
  //       path: 'chat',
  //       name: 'top-menu-chat',
  //       component: Chat,
  //     },
  //     {
  //       path: 'post',
  //       name: 'top-menu-post',
  //       component: Post,
  //     },
  //     {
  //       path: 'calendar',
  //       name: 'top-menu-calendar',
  //       component: Calendar,
  //     },
  //     {
  //       path: 'crud-data-list',
  //       name: 'top-menu-crud-data-list',
  //       component: CrudDataList,
  //     },
  //     {
  //       path: 'crud-form',
  //       name: 'top-menu-crud-form',
  //       component: CrudForm,
  //     },
  //     {
  //       path: 'users-layout-1',
  //       name: 'top-menu-users-layout-1',
  //       component: UsersLayout1,
  //     },
  //     {
  //       path: 'users-layout-2',
  //       name: 'top-menu-users-layout-2',
  //       component: UsersLayout2,
  //     },
  //     {
  //       path: 'users-layout-3',
  //       name: 'top-menu-users-layout-3',
  //       component: UsersLayout3,
  //     },
  //     {
  //       path: 'profile-overview-1',
  //       name: 'top-menu-profile-overview-1',
  //       component: ProfileOverview1,
  //     },
  //     {
  //       path: 'profile-overview-2',
  //       name: 'top-menu-profile-overview-2',
  //       component: ProfileOverview2,
  //     },
  //     {
  //       path: 'profile-overview-3',
  //       name: 'top-menu-profile-overview-3',
  //       component: ProfileOverview3,
  //     },
  //     {
  //       path: 'wizard-layout-1',
  //       name: 'top-menu-wizard-layout-1',
  //       component: WizardLayout1,
  //     },
  //     {
  //       path: 'wizard-layout-2',
  //       name: 'top-menu-wizard-layout-2',
  //       component: WizardLayout2,
  //     },
  //     {
  //       path: 'wizard-layout-3',
  //       name: 'top-menu-wizard-layout-3',
  //       component: WizardLayout3,
  //     },
  //     {
  //       path: 'blog-layout-1',
  //       name: 'top-menu-blog-layout-1',
  //       component: BlogLayout1,
  //     },
  //     {
  //       path: 'blog-layout-2',
  //       name: 'top-menu-blog-layout-2',
  //       component: BlogLayout2,
  //     },
  //     {
  //       path: 'blog-layout-3',
  //       name: 'top-menu-blog-layout-3',
  //       component: BlogLayout3,
  //     },
  //     {
  //       path: 'pricing-layout-1',
  //       name: 'top-menu-pricing-layout-1',
  //       component: PricingLayout1,
  //     },
  //     {
  //       path: 'pricing-layout-2',
  //       name: 'top-menu-pricing-layout-2',
  //       component: PricingLayout2,
  //     },
  //     {
  //       path: 'invoice-layout-1',
  //       name: 'top-menu-invoice-layout-1',
  //       component: InvoiceLayout1,
  //     },
  //     {
  //       path: 'invoice-layout-2',
  //       name: 'top-menu-invoice-layout-2',
  //       component: InvoiceLayout2,
  //     },
  //     {
  //       path: 'faq-layout-1',
  //       name: 'top-menu-faq-layout-1',
  //       component: FaqLayout1,
  //     },
  //     {
  //       path: 'faq-layout-2',
  //       name: 'top-menu-faq-layout-2',
  //       component: FaqLayout2,
  //     },
  //     {
  //       path: 'faq-layout-3',
  //       name: 'top-menu-faq-layout-3',
  //       component: FaqLayout3,
  //     },
  //     {
  //       path: 'update-profile',
  //       name: 'top-menu-update-profile',
  //       component: UpdateProfile,
  //     },
  //     {
  //       path: 'change-password',
  //       name: 'top-menu-change-password',
  //       component: ChangePassword,
  //     },
  //     {
  //       path: 'regular-table',
  //       name: 'top-menu-regular-table',
  //       component: RegularTable,
  //     },
  //     {
  //       path: 'tabulator',
  //       name: 'top-menu-tabulator',
  //       component: Tabulator,
  //     },
  //     {
  //       path: 'modal',
  //       name: 'top-menu-modal',
  //       component: Modal,
  //     },
  //     {
  //       path: 'slide-over',
  //       name: 'top-menu-slide-over',
  //       component: SlideOver,
  //     },
  //     {
  //       path: 'notification',
  //       name: 'top-menu-notification',
  //       component: Notification,
  //     },
  //     {
  //       path: 'tab',
  //       name: 'top-menu-tab',
  //       component: Tab,
  //     },
  //     {
  //       path: 'accordion',
  //       name: 'top-menu-accordion',
  //       component: Accordion,
  //     },
  //     {
  //       path: 'button',
  //       name: 'top-menu-button',
  //       component: Button,
  //     },
  //     {
  //       path: 'alert',
  //       name: 'top-menu-alert',
  //       component: Alert,
  //     },
  //     {
  //       path: 'progress-bar',
  //       name: 'top-menu-progress-bar',
  //       component: ProgressBar,
  //     },
  //     {
  //       path: 'tooltip',
  //       name: 'top-menu-tooltip',
  //       component: Tooltip,
  //     },
  //     {
  //       path: 'dropdown',
  //       name: 'top-menu-dropdown',
  //       component: Dropdown,
  //     },
  //     {
  //       path: 'typography',
  //       name: 'top-menu-typography',
  //       component: Typography,
  //     },
  //     {
  //       path: 'icon',
  //       name: 'top-menu-icon',
  //       component: Icon,
  //     },
  //     {
  //       path: 'loading-icon',
  //       name: 'top-menu-loading-icon',
  //       component: LoadingIcon,
  //     },
  //     {
  //       path: 'regular-form',
  //       name: 'top-menu-regular-form',
  //       component: RegularForm,
  //     },
  //     {
  //       path: 'datepicker',
  //       name: 'top-menu-datepicker',
  //       component: Datepicker,
  //     },
  //     {
  //       path: 'tom-select',
  //       name: 'top-menu-tom-select',
  //       component: TomSelect,
  //     },
  //     {
  //       path: 'file-upload',
  //       name: 'top-menu-file-upload',
  //       component: FileUpload,
  //     },
  //     {
  //       path: 'wysiwyg-editor',
  //       name: 'top-menu-wysiwyg-editor',
  //       component: WysiwygEditor,
  //     },
  //     {
  //       path: 'validation',
  //       name: 'top-menu-validation',
  //       component: Validation,
  //     },
  //     {
  //       path: 'chart',
  //       name: 'top-menu-chart',
  //       component: Chart,
  //     },
  //     {
  //       path: 'slider',
  //       name: 'top-menu-slider',
  //       component: Slider,
  //     },
  //     {
  //       path: 'image-zoom',
  //       name: 'top-menu-image-zoom',
  //       component: ImageZoom,
  //     },
  //   ],
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.query.lang
  if (!LANGUAGES.includes(language)) {
    language = LANGUAGES[0]
  }
  const { locale } = i18n.global
  locale.value = language

  // let requiresAuth = to.meta.hasOwnProperty('requiresAuth')
  //   ? to.meta.requiresAuth
  //   : true

  // // checking if the user is authenticated
  // if (!store.getters.isAuthenticated() && requiresAuth) {
  //   next({ name: 'login', params: { ...{ redirect: to.name }, ...to.params } })
  //   return
  // } else if (
  //   store.getters.isAuthenticated() &&
  //   !store.getters.isDataPreloaded() &&
  //   to.name !== 'preloading'
  // ) {
  //   //... comment
  //   next({
  //     name: 'preloading',
  //     params: { ...{ redirect: to.name }, ...to.params },
  //   })
  //   return
  // }

  next()
})

export default router
