<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.inventoryManagement')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('page.retail.stockAdjustment.addButton')"
        @click="showModalAdd"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: StockAdjustment List -->
        <TableComponent
          :is-show-search="true"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.retail.stockAdjustment.title')"
          :header="stockAdjustmentHeader"
          :is-not-empty="stockAdjustmentData.length > 0"
          :meta-data="metaDataStockAdjustment"
          :show-icon="false"
          search-placeholder="page.retail.stockAdjustment.search"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <template #additionalPreFilter>
            <div class="relative text-slate-500 shadow sm:ml-auto">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="dateFilter"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  format: 'YYYY-MM-DD',
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control box pl-8 sm:w-56"
              />
            </div>
          </template>

          <tr
            v-for="data in stockAdjustmentData"
            :key="data.id"
            class="cursor-pointer"
            @click="getDetailStockAdjustment(data.id)"
          >
            <td class="uppercase">{{ getSplitString(data.id, '-', 0, '') }}</td>
            <td>
              {{ formattedDate(subDate(data.created), 'DD MMMM YYYY') }}
            </td>
            <td class="text-center">{{ data.items.length }}</td>
            <td class="text-right">{{ countItemQuantity(data.items) }}</td>
          </tr>
        </TableComponent>
        <!-- END: StockAdjustment List -->
      </div>
    </div>

    <!-- BEGIN: Modal Add StockAdjustment -->
    <Modal
      :show="showAddStockAdjustmentModal"
      size="modal-xl"
      @hidden="closeModal('add')"
    >
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="stockAdjustmentSchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveStockAdjustment"
        >
          <div class="flex flex-col space-y-4 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex items-center space-x-1">
                <PackageIcon />
                <p class="font-bold">
                  <span v-if="!isEdit">
                    {{ $t('page.retail.stockAdjustment.forms.title') }}
                  </span>
                  <span v-else>
                    {{ $t('page.retail.stockAdjustment.forms.editTitle') }}
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="stockAdjustmentCategoryCode"
                  >
                    <span>
                      {{ $t('page.retail.stockAdjustment.forms.reason')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <div class="flex space-x-4">
                    <div
                      v-for="(category, key) in stockAdjustmentCategoryCodes"
                      :key="key"
                    >
                      <input
                        v-model="stockAdjustmentCategoryCode"
                        type="radio"
                        :value="category.value"
                        class="text-primary"
                      />
                      {{ category.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="stockAdjustmentReason"
                  >
                    <span>
                      {{ $t('page.retail.stockAdjustment.forms.reasonDetail')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="stockAdjustmentReason"
                    name="stockAdjustmentReason"
                  >
                    <select
                      id="stockAdjustmentReason"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.stockAdjustmentReason !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                    >
                      <option value="" disabled hidden>
                        {{
                          $t(
                            'page.retail.stockAdjustment.forms.reasonDetailPlaceholder'
                          )
                        }}
                      </option>
                      <template
                        v-if="stockAdjustmentCategoryCode === 'STOCK_IN'"
                      >
                        <option
                          v-for="detailReason in stockInDetailReasons"
                          :key="detailReason"
                          :value="detailReason"
                        >
                          {{ detailReason }}
                        </option>
                      </template>
                      <template v-else>
                        <option
                          v-for="detailReason in stockOutDetailReasons"
                          :key="detailReason"
                          :value="detailReason"
                        >
                          {{ detailReason }}
                        </option>
                      </template>
                    </select>
                  </Field>

                  <span class="text-xs text-red-400">
                    <template v-if="errors.stockAdjustmentReason !== undefined">
                      {{ $t(errors.stockAdjustmentReason) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="stockAdjustmentNote"
                  >
                    {{ $t('page.retail.stockAdjustment.forms.note') }}
                  </label>
                </div>
                <div>
                  <textarea
                    id="stockAdjustmentNote"
                    v-model="stockAdjustmentNote"
                    required="required"
                    class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    :placeholder="
                      $t('page.retail.stockAdjustment.forms.notePlaceholder')
                    "
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-6">
              <TableComponent
                :is-show-search="false"
                :is-show-pagination="false"
                :show-title="false"
                :header="
                  stockAdjustmentCategoryCode === 'STOCK_IN'
                    ? stockAdjustmentInFormHeader
                    : stockAdjustmentOutFormHeader
                "
                :add-empty-header="true"
                wrapper-class="-mt-3"
                wrapper-table-class="pb-16"
              >
                <template v-if="stockAdjustmentItems.length > 0">
                  <tr
                    v-for="item in stockAdjustmentItems"
                    :key="item.id"
                    class="cursor-pointer"
                  >
                    <td class="w-full">
                      <ComboBox
                        v-model="selectedItemProductIds[item.id]"
                        :class="[
                          stockAdjustmentProductError &&
                          selectedItemProductIds[item.id] === undefined
                            ? 'border border-red-400'
                            : 'border-none',
                        ]"
                        :load-options="searchProduct"
                        @selected="selectedProduct($event, item.id)"
                      />
                    </td>
                    <td class="text-center text-gray-500">
                      <span
                        v-if="
                          selectedItemProductIds[item.id] !== undefined &&
                          !checkEmptyObject(selectedItemProductIds[item.id]) &&
                          'stock' in selectedItemProductIds[item.id]
                        "
                      >
                        {{ selectedItemProductIds[item.id].stock }}
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-center">
                      <input
                        v-model="stockAdjustmentItemQuantities[item.id]"
                        type="number"
                        required="required"
                        class="w-full appearance-none rounded text-center focus:border-none focus:bg-white focus:outline-none focus:ring-0"
                        min="0"
                        placeholder="0"
                        :class="[
                          stockAdjustmentItemQuantitiesError &&
                          (stockAdjustmentItemQuantities[item.id] ===
                            undefined ||
                            stockAdjustmentItemQuantities[item.id] === null ||
                            stockAdjustmentItemQuantities[item.id] === '')
                            ? 'border border-red-400'
                            : 'border-none',
                        ]"
                        @keypress="isInputNumber($event)"
                      />
                    </td>
                    <td class="text-center text-gray-500">
                      <p
                        v-if="
                          item.id in selectedItemProductIds &&
                          'stock' in selectedItemProductIds[item.id] &&
                          item.id in stockAdjustmentItemQuantities
                        "
                      >
                        <template
                          v-if="
                            isNumber(stockAdjustmentItemQuantities[item.id])
                          "
                        >
                          <span
                            v-if="stockAdjustmentCategoryCode === 'STOCK_IN'"
                          >
                            {{
                              parseInt(stockAdjustmentItemQuantities[item.id]) +
                              parseInt(selectedItemProductIds[item.id].stock)
                            }}
                          </span>
                          <span v-else>
                            {{
                              parseInt(selectedItemProductIds[item.id].stock) -
                              parseInt(stockAdjustmentItemQuantities[item.id])
                            }}
                          </span>
                        </template>
                        <template v-else> - </template>
                      </p>
                      <p v-else>-</p>
                    </td>
                    <td class="space-x-2">
                      <div class="flex space-x-2">
                        <button
                          class="flex items-center space-x-1 rounded-sm bg-danger px-2 py-1 font-medium text-white"
                          @click="deleteItem(item.id)"
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </TableComponent>
            </div>
            <div
              class="flex w-full cursor-pointer"
              :class="
                stockAdjustmentError ? 'border border-red-500 text-red-500' : ''
              "
              @click="addNewItem()"
            >
              <p class="text-gray-800">
                + {{ $t('page.retail.stockAdjustment.forms.addProduct') }}
              </p>
            </div>
          </div>
          <div class="flex justify-end space-x-2 px-5 pb-8">
            <button
              type="button"
              class="w-24 text-primary"
              @click="closeModal('add')"
            >
              {{ $t('button.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary">
              {{ $t('page.retail.stockAdjustment.forms.createAdjustment') }}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add StockAdjustment -->

    <!-- END: Modal Detail StockAdjustment -->
    <Modal
      :show="showDetailStockAdjustmentModal"
      size="modal-xl"
      @hidden="closeModal('detail')"
    >
      <ModalBody class="p-4">
        <div
          v-if="!checkEmptyObject(stockAdjustmentDetailData)"
          class="space-y-4"
        >
          <div class="flex items-center justify-between">
            <p class="text-sm font-semibold">Stock Purchase</p>
            <p class="text-xs text-gray-500">
              {{
                formattedDate(
                  subDate(stockAdjustmentDetailData.created),
                  'dddd, DD MMMM YYYY, HH.mm.ss ZZ'
                )
              }}
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>No. Adjustment</p>
            <p class="uppercase">
              {{ getSplitString(stockAdjustmentDetailData.id) }}
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>Tanggal Adjustment</p>
            <p class="max-w-full truncate">
              {{
                formattedDate(
                  subDate(stockAdjustmentDetailData.created),
                  'dddd, DD MMMM YYYY'
                )
              }}
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>Alasan</p>
            <p>
              {{
                stockAdjustmentDetailData.category_code === 'STOCK_IN'
                  ? 'Barang masuk'
                  : 'Barang keluar'
              }}
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>Detil Alasan</p>
            <p>
              {{ stockAdjustmentDetailData.reason || '-' }}
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>Catatan</p>
            <p class="max-w-full truncate">
              {{ stockAdjustmentDetailData.notes || '-' }}
            </p>
          </div>

          <div class="flex w-full items-center justify-center">
            <TableComponent
              :is-show-search="false"
              :is-show-pagination="false"
              :show-title="false"
              :header="
                stockAdjustmentDetailData.category_code === 'STOCK_IN'
                  ? stockAdjustmentInDetailHeader
                  : stockAdjustmentOutDetailHeader
              "
              :add-empty-header="true"
              wrapper-class="-mt-3 w-full"
              wrapper-table-class="pb-2"
            >
              <tr
                v-for="(item, key) in stockAdjustmentDetailData.items"
                :key="key"
                class="cursor-pointer"
              >
                <td>
                  <p v-if="'product' in item">
                    {{ item.product.name }}
                  </p>
                </td>
                <td class="text-right">{{ item.stock_before }}</td>
                <td class="text-right">{{ item.quantity }}</td>
                <td class="text-right">{{ item.stock_after }}</td>
              </tr>
            </TableComponent>
          </div>

          <div class="flex justify-end pt-2 pb-5">
            <button
              type="button"
              class="btn-primary btn text-xs"
              @click="closeModal('detail')"
            >
              {{ $t('button.close') }}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <!-- END: Modal Detail StockAdjustment -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>
<script>
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import retailMixin from '@/mixins/retail.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import ComboBox from '@/components/select-search/ComboBox.vue'

import PackageIcon from '@/assets/svg/package.svg'
import DeleteIcon from '@/assets/svg/delete.svg'

export default {
  name: 'SimplePurchasingStockPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    ButtonComponent,
    DeleteModal,
    ComboBox,

    PackageIcon,
    DeleteIcon,
  },
  mixins: [globalMixin, tenantMixin, retailMixin],
  data: () => ({
    showDetailStockAdjustmentModal: false,
    showAddStockAdjustmentModal: false,
    dateFilter: '',

    stockAdjustmentHeader: [
      {
        item: 'page.retail.stockAdjustment.tableHeaders.adjustmentNo',
        customClass: '',
      },
      {
        item: 'page.retail.stockAdjustment.tableHeaders.date',
        customClass: '',
      },
      {
        item: 'page.retail.stockAdjustment.tableHeaders.reason',
        customClass: 'text-center',
      },
      {
        item: 'page.retail.stockAdjustment.tableHeaders.itemTotal',
        customClass: 'text-right',
      },
    ],
    stockAdjustmentInFormHeader: [
      {
        item: 'page.retail.stockAdjustment.forms.tableHeaders.productName',
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.inv',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.in',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.new',
        ],
        customClass: 'text-xs',
      },
      {
        item: 'action.action',
        customClass: 'text-xs',
      },
    ],
    stockAdjustmentOutFormHeader: [
      {
        item: 'page.retail.stockAdjustment.forms.tableHeaders.productName',
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.inv',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.out',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.new',
        ],
        customClass: 'text-xs',
      },
      {
        item: 'action.action',
        customClass: 'text-xs',
      },
    ],
    stockAdjustmentInDetailHeader: [
      {
        item: 'page.retail.stockAdjustment.forms.tableHeaders.productName',
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.inv',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.in',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.new',
        ],
        customClass: 'text-xs',
      },
    ],
    stockAdjustmentOutDetailHeader: [
      {
        item: 'page.retail.stockAdjustment.forms.tableHeaders.productName',
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.inv',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.out',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.stockAdjustment.forms.tableHeaders.stockTotal',
          'page.retail.stockAdjustment.forms.tableHeaders.new',
        ],
        customClass: 'text-xs',
      },
    ],

    // Form Validation
    stockAdjustmentSchema: Yup.object().shape({
      stockAdjustmentReason: Yup.string()
        .typeError('formError.detailReason.required')
        .required('formError.detailReason.required')
        .matches(/^(?!\s+$).*/, 'formError.detailReason.required'),
    }),
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      if (this.tenantId !== '' && !this.isLoading) {
        this.getAllStockAdjustment(this.filterDates)
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        if (!this.isLoading) {
          this.getAllStockAdjustment(this.filterDates)
        }
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          if (!this.isLoading) {
            this.getAllStockAdjustment(this.filterDates)
          }
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    changeFilter(event) {
      this.filterDataStockAdjustment = event.filterData
      this.getAllStockAdjustment(this.filterDates)
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllStockAdjustment(this.filterDates)
    },
    showModalAdd() {
      this.showAddStockAdjustmentModal = true
      this.isEdit = false
      this.resetData(false)
    },
    getDetailStockAdjustment(id) {
      this.getStockAdjustmentDetailAPI(id).then((res) => {
        this.showDetailStockAdjustmentModal = true
      })
    },
    saveStockAdjustment() {
      if (this.isLoadingSave) return

      const params = {}
      params.tenant_id = this.tenantId
      params.inventory_category_code = this.stockAdjustmentCategoryCode
      params.reason = this.stockAdjustmentReason
      params.notes = this.stockAdjustmentNote

      const items = []
      this.stockAdjustmentItems.forEach((item) => {
        const data = {}
        if (item.id in this.selectedItemProductIds) {
          data['product_id'] = this.selectedItemProductIds[item.id].product_id

          this.stockAdjustmentProductError = false
        } else {
          this.message = this.$t(
            'formError.simplePurchasingStock.stockItemProductRequired'
          )
          this.stockAdjustmentProductError = true

          return
        }

        if (
          item.id in this.stockAdjustmentItemQuantities &&
          this.stockAdjustmentItemQuantities[item.id] !== null &&
          this.stockAdjustmentItemQuantities[item.id] !== ''
        ) {
          data['quantity'] = this.stockAdjustmentItemQuantities[item.id]

          this.stockAdjustmentItemQuantitiesError = false
        } else {
          this.message = this.$t(
            'formError.simplePurchasingStock.stockItemQuantityRequired'
          )
          this.stockAdjustmentItemQuantitiesError = true

          return
        }

        items.push(data)
      })

      if (
        this.stockAdjustmentProductError ||
        this.stockAdjustmentItemQuantitiesError
      ) {
        setTimeout(() => {
          this.showToast('failed')
        }, 500)
        return
      }

      params.items = items

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.activeId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.activeId

        this.saveStockAdjustmentAPI(params, true)
      } else {
        this.saveStockAdjustmentAPI(params, false)
      }
    },
    closeModal(type = 'add') {
      if (type === 'add') {
        this.showAddStockAdjustmentModal = false
      } else {
        this.showDetailStockAdjustmentModal = false
      }
    },
    confirmDelete(id) {
      this.deleteStockAdjustmentAPI(id)
    },
    resetData(afterSave = true) {
      this.stockAdjustmentId = null
      this.stockAdjustmentSupplierId = ''
      this.stockAdjustmentNote = ''
      this.stockAdjustmentItemQuantities = []
      this.stockAdjustmentItems = []
      this.selectedItemProductIds = []
      this.stockAdjustmentError = false
      this.stockAdjustmentProductError = false
      this.stockAdjustmentItemQuantitiesError = false
      this.resetSelectedProduct()

      this.addNewItem()

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            stockAdjustmentSupplierId: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddStockAdjustmentModal = false
        this.isEdit = false
        this.getAllStockAdjustment(this.filterDates, false)
      }
    },
    addNewItem() {
      const generatedId = this.generateId()
      this.stockAdjustmentItems.push({ id: generatedId })
      this.stockAdjustmentItemQuantities[generatedId] = ''
      this.stockAdjustmentError = false
    },
    deleteItem(id) {
      this.stockAdjustmentItems = this.stockAdjustmentItems.filter(
        (item) => item.id !== id
      )
    },
    countItemQuantity(items) {
      return items.reduce((val, currVal) => {
        val += currVal.quantity
        return val
      }, 0)
    },
  },
}
</script>
