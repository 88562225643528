import {
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
  checkTimeLoggedIn,
} from '../plugins/basic-function'

import Toastify from 'toastify-js'
import { colors } from '@/utils/colors'

export default {
  inject: ['dayjs'],
  data: () => ({
    appName: 'InKanteen',
    userAgent: '',
    activeUser: {
      id: null,
      created: null,
      modified: null,
      full_name: null,
      email: null,
      phone_number: null,
      avatar: null,
      is_verified: true,
      institute: null,
      institute_slug: null,
      role: null,
      role_position: null,
    },
    instituteId: null,

    userMenus: {},
    userFeatures: {},

    maintenanceToPage: false,
    isAuthenticated: false,

    showModalMaintenance: false,
    showModalComingSoon: false,
    showModalLogout: false,

    multiSelectClasses: {
      container:
        'relative mx-auto w-full h-11 flex items-center justify-end box-border cursor-pointer border border-gray-200 rounded-md bg-white text-sm leading-snug outline-none',
      containerDisabled: 'cursor-default bg-gray-100',
      containerOpen: 'rounded-b-none',
      containerOpenTop: 'rounded-t-none',
      containerActive: 'ring ring-primary ring-opacity-30',
      search:
        'w-full absolute rounded-md inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-xs bg-white',
      placeholder:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
      clearIcon:
        'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
      option:
        'flex items-center justify-start box-border text-left cursor-pointer text-xs leading-snug py-2 px-3',
      optionPointed: 'text-gray-800 bg-gray-100',
      optionSelected: 'text-white bg-primary text-white',
      optionSelectedPointed: 'is-selected bg-primary font-bold text-white',
      optionDisabled: 'text-gray-300 cursor-not-allowed',
      noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
      noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
      spinner: 'multiselect-spinner',
      infinite: 'multiselect-infinite',
      infiniteSpinner: 'multiselect-infinite-spinner',
      tagRemove:
        'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
      tagRemoveIcon:
        'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
      clear: 'multiselect-clear',
      clearIcon: 'multiselect-clear-icon',
    },
    message: '',
    showModal: false,
    showModalInfo: false,
    showModalDel: false,
    deleteId: '',
    isLoading: false,
    isLoadingSave: false,
    isEdit: false,
    showWarning: false,
    warning: '',

    createdData: {},
    userTenantList: [],
    selectedTenantIds: [],
    numberFormat: {
      decimal: ',',
      separator: '.',
      prefix: 'Rp',
      precision: 2,
      masked: true,
    },
    dateLocaleOptions: {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour12: false,
      timeZone: 'Asia/Jakarta',
      timeZoneName: 'short',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },

    logoutModal: false,
    menuRetail: [
      'employee',
      'omnichannel',
      'retail-inventory-unit',
      'retail-inventory-management-stock-adjustment',
      'retail-inventory-management-supplier',
    ],
    menuRetailOnly: [
      'retail-inventory-unit',
      'retail-inventory-management-supplier',
      'retail-inventory-management-stock-purchase',
      'retail-inventory-management-stock-adjustment',
    ],
    retailAllowGlobal: [],
    menuDashboardSuperInstituteOnly: [
      'dashboard-super-institute',
      'report-summary',
    ],
    menuSuperInstituteAdminOnly: ['income', 'expense'],
    forbiddenMenu: ['dashboard-super-institute'],
  }),
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters['auth/isUserLoggedIn']
      },
      set(userLogggedIn) {
        return this.$store.dispatch('auth/updateLoginState', userLogggedIn)
      },
    },
    isUnderMaintenance: {
      get() {
        // return this.underMaintenance
        return this.$store.getters['home/underMaintenanceGetter']
      },
      set(maintenance) {
        return this.$store.dispatch('home/setUnderMaintenance', maintenance)
      },
    },

    selectedTenantId: {
      get() {
        this.selectedTenantIds =
          this.$store.getters['tenant/selectedTenantIdGetter']
        return this.$store.getters['tenant/selectedTenantIdGetter']
      },
      set(val) {
        this.selectedTenantIds = val
        return this.$store.dispatch('tenant/setSelectedTenantId', val)
      },
    },
    activeUserStore() {
      return this.$store.getters['auth/activeUser']
    },
    activeTenantStore() {
      return this.$store.getters['auth/activeTenant']
    },
    tenantTotalStore() {
      return this.$store.getters['auth/tenantTotal']
    },
    userLoggedInDate() {
      return this.$store.getters['auth/userLoggedInDate']
    },
    isInstituteAdmin() {
      return (
        this.activeUserStore !== null &&
        this.activeUserStore.role.role === 'Institute Admin'
      )
    },
    isInstituteSuperAdmin() {
      return (
        this.activeUserStore !== null &&
        ['Super Institute Admin'].includes(this.activeUserStore.role.role)
      )
    },
    isInstituteSuperAdminMode() {
      return this.isInstituteSuperAdmin && this.activeTenantStore === null
    },
    isInstituteRetail() {
      return (
        this.activeUserStore !== null &&
        'institute' in this.activeUserStore &&
        'type' in this.activeUserStore.institute &&
        'name' in this.activeUserStore.institute.type &&
        this.activeUserStore.institute.type.name.toUpperCase() === 'RETAIL'
      )
    },
    isCanStockPurchase() {
      return (
        this.isInstituteRetail ||
        (this.activeUserStore !== null &&
          this.activeUserStore.email.includes('noega'))
      )
    },
    selectedTenantIdString() {
      if (this.activeTenantStore !== null) {
        return this.activeTenantStore.id
      } else if (this.selectedTenantId.length > 0) {
        return this.selectedTenantId.toString()
      } else {
        this.getLocalUserTenant()
        const userTenantListId = this.userTenantList.reduce(function (
          accumulator,
          currVal
        ) {
          accumulator.push(currVal.tenant_id)
          return accumulator
        },
        [])

        return userTenantListId.toString()
      }
    },

    hasStockPurchaseCart() {
      return this.stockPurchaseCart.length > 0
    },
    stockPurchaseCart: {
      get() {
        return this.$store.getters['stockPurchase/stockPurchaseCart']
      },
      set(stockPurchaseCart) {
        return this.$store.dispatch(
          'stockPurchase/setStockPurchaseCart',
          stockPurchaseCart
        )
      },
    },
    stockPurchaseCartBySupplier() {
      if (this.stockPurchaseCart.length > 0) {
        return this.stockPurchaseCart.reduce((filtered, item) => {
          let key = `${item.supplierId}|${item.supplierData.name}`
          if (filtered[key]) {
            filtered[key].push(item)
          } else {
            filtered[key] = [item]
          }
          return filtered
        }, {})
      } else {
        return {}
      }
    },
    stockPurchaseCartQtyTotal() {
      if (this.stockPurchaseCart.length > 0) {
        return this.stockPurchaseCart.reduce((filtered, item) => {
          filtered += item.qty
          return filtered
        }, 0)
      } else {
        return 0
      }
    },
    stockPurchaseCartPriceTotal() {
      if (this.stockPurchaseCart.length > 0) {
        return this.stockPurchaseCart.reduce((filtered, item) => {
          filtered += item.qty * item.productData.sale_price
          return filtered
        }, 0)
      } else {
        return 0
      }
    },
    stockPurchaseCartItems() {
      if (this.stockPurchaseCart.length > 0) {
        return this.stockPurchaseCart.reduce((filtered, item) => {
          filtered.push({
            supplier_product_id: item.productId,
            quantity: item.qty,
          })
          return filtered
        }, [])
      } else {
        return []
      }
    },

    todayDate() {
      return this.dayjs().format('D')
    },
  },
  watch: {
    // selectedTenantIds: function (val) {
    // console.log(val)
    // this.getSalesSummary()
    // },
  },
  mounted() {
    this.userAgent = navigator.userAgent
  },
  methods: {
    checkZero(event) {
      const num = event.target.value.replace(/\D+/g, '')
      if (num <= 0) event.target.value = ''
    },
    checkIsAuthenticated() {
      const accessToken = localStorage.getItem('accessToken') || null

      if (accessToken !== null) {
        this.$router.push({
          name: 'home',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    },
    toLoginPage() {
      this.$router.push({
        name: 'login',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    },
    toDashboardSuperInstitutePage() {
      if (this.isInstituteSuperAdmin) {
        this.$store.dispatch('menu/resetActiveMenu')
        this.$store.dispatch('auth/removeActiveTenant')
        this.$router.push({
          name: 'dashboard-super-institute',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    },
    checkMaintenance() {
      if (this.isUnderMaintenance) {
        if (this.maintenanceToPage) this.$router.push({ name: 'maintenance' })
        else this.$emit('showModalMaintenance', this.isUnderMaintenance)
      }
    },
    isPhoneNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    mergeText(text1, text2) {
      return text1 + ' ' + text2
    },
    persentage(price, discount) {
      return Math.floor(((price - discount) / price) * 100)
    },
    imageUrlAlternate(event) {
      crossOriginIsolated.log('called imageUrlAlternate')
      event.target.src = '@/assets/image/icon.png'
    },
    imageUrlAlternateBox(event) {
      event.target.src = '@/assets/image/icon.png'
    },
    formatDateLocale(
      date,
      options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour12: false,
        timeZone: 'UTC',
        timeZoneName: 'short',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }
    ) {
      return new Date(date).toLocaleString('id-ID', options)
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },
    formattedDate(value, format = 'DD MMMM YYYY') {
      if (!value) return ''
      const formattedDate = this.dayjs(value).format(format)
      return formattedDate
    },
    hoursMinutesFromTwoDates(date1, date2) {
      const localStartTime = this.dayjs(date1)
      const localEndTime = this.dayjs(date2)

      const hoursDiff = localEndTime.diff(localStartTime, 'h')
      const minutesDiff = localEndTime.diff(localStartTime, 'm')

      let hoursMinutes = ''
      if (hoursDiff > 0) {
        hoursMinutes =
          hoursDiff +
          ' ' +
          this.$t('info.timeHour') +
          ' ' +
          minutesDiff +
          ' ' +
          this.$t('info.timeMinute')
      } else {
        hoursMinutes = minutesDiff + ' ' + this.$t('info.timeMinute')
      }
      return hoursMinutes
    },
    timeFromNow(date) {
      return this.dayjs(date).fromNow()
    },
    dateStillActive(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      return endDate > startDate
    },
    dateDiffDays(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      const diffTime = endDate - startDate
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },
    diffPrice(price, discountedPrice) {
      return discountedPrice - price
    },
    hoursMinutesFromDate(date) {
      const localDateTime = this.dayjs(date).format('HH:mm')
      return localDateTime
    },
    getDateRange(totalDay = 30) {
      const curr = new Date()
      const dateList = []
      dateList.push(
        new Date(curr.setDate(curr.getDate())).toISOString().slice(0, 10)
      )

      for (let i = 1; i <= totalDay; i++) {
        const first = curr.getDate() + 1
        const day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        dateList.push(day)
      }
      return dateList
    },
    getRangeInteger(start, end, length = end - start) {
      return Array.from({ length }, (_, i) => start + i)
    },
    timeEstimatedFromDate(date, addMinutes = 5) {
      const startTime = this.dayjs(date).format('HH:mm')

      const endTime = this.dayjs(date).add(addMinutes, 'm').format('HH:mm')
      return `${startTime} - ${endTime}`
    },
    mergeTwoArrayByKey(arr1, arr2, key = 'id') {
      return arr1
        .filter((elem) => !arr2.find((subElem) => subElem[key] === elem[key]))
        .concat(arr2)
    },
    toDatetime(date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      const hours = ('0' + date.getHours()).slice(-2)
      const minutes = ('0' + date.getMinutes()).slice(-2)
      const seconds = ('0' + date.getSeconds()).slice(-2)

      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
      )
    },
    arrayToString(array, key) {
      return array.map((item) => item[key]).join(', ')
    },
    onCopy(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_success'),
        type: 'default',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    onError(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_failed'),
        type: 'error',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    showToast(id) {
      Toastify({
        node: dom('#' + id)
          .clone()
          .removeClass('hidden')[0],
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    },
    sumObjectValue(obj) {
      var sum = 0
      for (var el in obj) {
        if (obj.hasOwnProperty(el)) {
          sum += parseFloat(obj[el])
        }
      }
      return sum
    },
    checkEmptyObject(obj) {
      // because Object.keys(new Date()).length === 0;
      // we have to do some additional check
      return (
        obj && // 👈 null and undefined check
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      )
    },
    extractFilename(dispotition, defaultName) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(dispotition)
      let filename = defaultName
      if (matches && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
      return filename
    },
    getColor(index) {
      switch (index) {
        case 0:
          return colors.primary()
        case 1:
          return colors.secondary()
        case 2:
          return colors.success()
        case 3:
          return colors.info()
        case 4:
          return colors.warning()
        case 5:
          return colors.pending()
        case 6:
          return colors.danger()
        case 7:
          return colors.light()
        case 8:
          return colors.dark()
        default:
          return colors.primary()
      }
    },
    checkArray(arr, comparison) {
      return arr.every((v) => v === comparison)
    },
    splitWords(text, numWords) {
      const words = text.split(' ')
      let part1 = '',
        part2 = ''
      words.forEach((word, idx) => {
        if (idx < numWords) {
          part1 += ' ' + word
        } else {
          part2 += ' ' + word
        }
      })
      return [part1.trim(), part2.trim()]
    },
    uniqByKeepLast(a, key) {
      return [...new Map(a.map((x) => [key(x), x])).values()]
    },
    getDayTranslation(dayIndex) {
      return this.$t(`day.${dayIndex}`)
    },
    resetModalDelete() {
      this.showModalDel = false
      this.deleteId = ''
    },
    showModalDelete(id) {
      this.showModalDel = true
      this.deleteId = id
    },
    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    },
    async getUserTenant() {
      this.$store
        .dispatch('tenant/getUserTenant', {
          instituteId: this.$route.params.slug,
          is_topup_channel: false,
        })
        .then((response) => {
          const responseData = response.data

          if (response.status === 200) {
            const mappedData = responseData.data.reduce(function (
              accumulator,
              currData
            ) {
              accumulator.push({
                tenant_id: currData.id,
                tenant_name: currData.name,
                value: currData.id,
                label: currData.name,
              })
              return accumulator
            },
            [])
            this.userTenantList = mappedData

            setLocalStorageWithExpiry(
              'inkanteenDashboard.userTenantList',
              mappedData,
              86400000
            )
          } else {
            const messageAlert = {
              show: true,
              message: response.message,
              type: 'error',
              position: 'center',
            }
            this.$emit('showToast', messageAlert)

            setLocalStorageWithExpiry(
              'inkanteenDashboard.userTenantList',
              [],
              86400000
            )
          }
        })
        .catch((error) => {
          const messageAlert = {
            show: true,
            message:
              error.response.data.detail ||
              error.response.message ||
              error.message,
            type: 'error',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
    },
    async getLocalUserTenant() {
      const userTenantList = getLocalStorageWithExpiry(
        'inkanteenDashboard.userTenantList'
      )
      if (userTenantList === null) {
        this.getUserTenant()
      } else {
        this.userTenantList = userTenantList
      }
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.logoutModal = false
        this.isLoggedIn = false
        this.$store.dispatch('tenant/removeUserTenant')
        this.$store.dispatch('menu/resetActiveMenu')

        this.$store.dispatch('stockPurchase/resetStockPurchaseCart')
        this.$store.dispatch('stockPurchase/resetSelectedAddress')

        this.$router.push({
          name: 'login',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      })
    },
    async checkNeedRefreshToken() {
      if (checkTimeLoggedIn()) {
        await this.$store
          .dispatch('auth/refreshToken')
          .then((response) => {
            location.reload()
            return response
          })
          .catch(() => {
            this.logout()
          })
      }
    },
    subDate(value, sub = 7, typeSub = 'hour') {
      return this.dayjs(value).subtract(sub, typeSub)
    },
    splitString(string, splitter = '-', index = null) {
      const stringArray = string.split(splitter)
      if (index === null) {
        return stringArray
      } else {
        return stringArray[index]
      }
    },
    getSplitString(string, splitter = '-', index = 0, prefix = '#') {
      if (string !== undefined) {
        const stringArray = string.split(splitter)
        if (
          stringArray.length > 0 &&
          typeof stringArray[index] !== 'undefined'
        ) {
          return prefix + '' + stringArray[index]
        } else {
          return '-'
        }
      }
      return '-'
    },
    getStatusOrderColor(status, type = 'bg') {
      // PENDING, PROCESS, DELIVERY, PAID, RECEIVED, COMPLETED, CANCELLED,
      const statusLower = status.toLowerCase()
      let bgColor = 'bg-gray-500'
      let textColor = 'text-gray-500'

      if (['pending', 'request'].includes(statusLower)) {
        bgColor = 'bg-darkOrange'
        textColor = 'text-darkOrange'
      } else if (['process', 'delivery'].includes(statusLower)) {
        bgColor = 'bg-darkYellow'
        textColor = 'text-darkYellow'
      } else if (['paid', 'received', 'completed'].includes(statusLower)) {
        bgColor = 'bg-success'
        textColor = 'text-success'
      } else if (['cancelled', 'rejected'].includes(statusLower)) {
        bgColor = 'bg-danger'
        textColor = 'text-danger'
      }

      if (type === 'bg') {
        return bgColor
      } else {
        return textColor
      }
    },
    generateId(length = 10) {
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
        counter += 1
      }
      return result
    },
    generateGreeting() {
      const today = new Date()
      const curHr = today.getHours()
      let currentGreeting = 'morning'

      if (curHr < 11) {
        currentGreeting = 'morning'
      } else if (curHr < 15) {
        currentGreeting = 'afternoon'
      } else if (curHr < 18) {
        currentGreeting = 'evening'
      } else {
        currentGreeting = 'night'
      }
      return currentGreeting
    },
    addToArrayUnique(arr, property = 'id', check = null, value = {}) {
      const index = arr.findIndex((item) => item[property] === check)

      if (index === -1) {
        arr.push(value)
      }
    },
    uniqueList(array) {
      return [...new Set(array)]
    },
    handleErrorMessage(message) {
      if (message !== undefined) {
        if (typeof message === 'string') {
          return message
        } else {
          if ('detail' in message) {
            if (Array.isArray(message.detail)) {
              return message.detail[0]
            } else {
              return message.detail
            }
          } else if ('error' in message) {
            return message.error
          }
        }
      }
    },
    getItemData(item, key = 'quantity') {
      if (key in item) {
        return item[key]
      }
    },
    isInputNumber(evt, withDot = false) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode

      let checkDot = true
      if (withDot) checkDot = charCode !== 46

      if (charCode > 31 && (charCode < 48 || charCode > 57) && checkDot) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    isNumber(value) {
      return typeof value === 'number'
    },
    dateFilterFormat(dateFilter) {
      if (dateFilter !== null && dateFilter !== undefined) {
        const date = dateFilter.split(' - ')
        let dateString = ''
        if (date.hasOwnProperty(0)) {
          dateString = this.formattedDate(date[0])
        }

        if (date.hasOwnProperty(1)) {
          dateString += ' s.d ' + this.formattedDate(date[1])
        }

        return dateString
      }
    },
    setDateFilter(startDate = '', endDate = '') {
      setLocalStorageWithExpiry(
        'inkanteenDashboard.filterDates',
        { start: startDate, end: endDate },
        86400000
      )
    },
    getDateFilter() {
      return getLocalStorageWithExpiry('inkanteenDashboard.filterDates')
    },
  },
}
