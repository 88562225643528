import { createStore } from 'vuex'
import global from './global'
import auth from './auth'
import address from './address'
import analytic from './analytic'
import balance from './balance'
import customer from './customer'
import finance from './finance'
import institute from './institute'
import inventory from './inventory'
import kiosk from './kiosk'
import menu from './menu'
import generalpayment from './generalpayment'
import omnichannel from './omnichannel'
import payment from './payment'
import paymentchannel from './paymentchannel'
import product from './product'
import privilege from './privilege'
import retail from './retail'
import report from './report'
import role from './role'
import sales from './sales'
import scm from './scm'
import shift from './shift'
import staff from './staff'
import stockPurchase from './stockPurchase'
import table from './table'
import tax from './tax'
import tenant from './tenant'
import transaction from './transaction'
import user from './user'
import variant from './variant'
import withdraw from './withdraw'

export default createStore({
  modules: {
    auth,
    address,
    analytic,
    balance,
    customer,
    finance,
    institute,
    inventory,
    kiosk,
    global,
    menu,
    generalpayment,
    omnichannel,
    payment,
    paymentchannel,
    product,
    privilege,
    retail,
    report,
    role,
    sales,
    scm,
    shift,
    staff,
    stockPurchase,
    table,
    tax,
    tenant,
    transaction,
    user,
    variant,
    withdraw,
  },
})
