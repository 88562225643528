<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.product.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        v-if="!isEdit"
        :title="$t('page.product.variantList.edit')"
        @click="edited"
      >
        <template #icon></template>
      </ButtonComponent>

      <template v-if="isEdit">
        <ButtonComponent :title="$t('button.cancel')" @click="cancelEdit">
        </ButtonComponent>
        <ButtonComponent :title="$t('button.save')" @click="saveEdited">
        </ButtonComponent>
      </template>
    </div>
  </div>

  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Variant List -->
        <TableComponent
          :date-string="dateFilter"
          :title="$t('page.product.variantList.title')"
          :header="variantListHeader"
          :show-hide="isEdit"
          :is-not-empty="variantData.length > 0 || isEdit"
          :is-show-pagination="variantData.length > 0 || !isEdit"
          :is-show-search="true"
          :is-show-search-with-filter="isInstitute"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <template #icon>
            <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
          </template>

          <tr v-if="isEdit">
            <td
              colspan="4"
              class="cursor-pointer text-center text-gray-500 hover:text-gray-700"
              @click="showModalAdd"
            >
              <p class="border border-dashed py-2">+ Tambah Variant</p>
            </td>
          </tr>

          <tr v-for="data in variantData" :key="data.id">
            <td>{{ data.name }}</td>
            <td>{{ extractOptions(data.options) }}</td>
            <td class="text-center">{{ data.product_linked_total }}</td>
            <td v-if="isEdit" class="space-x-2">
              <span
                class="cursor-pointer font-medium text-primary"
                @click="showModalUpdate(data)"
              >
                {{ $t('action.edit') }}
              </span>
              <span
                class="cursor-pointer font-medium text-danger"
                @click="showModalDelete(data.id)"
              >
                {{ $t('action.delete') }}
              </span>
            </td>
          </tr>
        </TableComponent>
        <!-- END: Variant List -->

        <!-- BEGIN: Modal Add Variant -->
        <Modal :show="showModal" size="modal-lg" @hidden="showModal = false">
          <ModalBody v-if="addSubVariantMode" class="p-4">
            <Form
              v-slot="{ errors }"
              :validation-schema="subVariantSchema"
              @submit="saveSubVariant"
            >
              <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
                <div class="flex flex-col space-y-3">
                  <div class="flex justify-between">
                    <p class="font-bold">
                      <span> Tambah sub-variant </span>
                    </p>
                  </div>
                </div>

                <div class="flex flex-col space-y-4">
                  <div class="flex w-full flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="variant-name"
                      >
                        Nama Sub-variant*
                      </label>
                    </div>
                    <div class="text-left">
                      <Field
                        v-slot="{ field }"
                        v-model="subVariantName"
                        name="subVariantName"
                      >
                        <input
                          id="subVariantName"
                          v-bind="field"
                          required="required"
                          class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                          :class="[
                            errors.subVariantName !== undefined
                              ? 'border-red-400'
                              : 'border-gray-200',
                          ]"
                          type="text"
                          placeholder="Nama Sub-Variant"
                        />
                      </Field>
                      <span class="text-xs text-red-400">
                        <template v-if="errors.subVariantName !== undefined">
                          {{ $t(errors.subVariantName) }}
                        </template>
                      </span>
                    </div>
                  </div>
                  <div class="flex w-full flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="sub-variant-price"
                      >
                        Biaya Tambahan
                      </label>
                    </div>
                    <div>
                      <input
                        id="sub-variant-price"
                        v-model="subVariantPrice"
                        required="required"
                        class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        type="number"
                        min="1"
                        placeholder="Biaya Tambahan"
                      />
                    </div>
                  </div>
                  <template
                    v-for="(total, key) in productIngredientSubVariantTotal"
                    :key="total"
                  >
                    <!--  -->
                    <div
                      v-if="!subVariantIngredientDeletedKey.includes(key)"
                      class="grid grid-cols-3 gap-2"
                    >
                      <div class="flex flex-col space-y-2">
                        <div>
                          <label
                            class="block text-left text-xs font-bold text-gray-700"
                            for="active"
                          >
                            Resep Bahan
                          </label>
                        </div>
                        <div class="flex">
                          <Multiselect
                            v-model="subVariantIngredients[key]"
                            value="ingredient_id"
                            label="ingredient_name"
                            track-by="ingredient_name"
                            :placeholder="$t('formLabel.select.ingredient')"
                            :min-chars="0"
                            :resolve-on-load="true"
                            :infinite="true"
                            :limit="filterData.take"
                            :delay="1000"
                            :options="
                              async (query) => {
                                return await searchIngredientOption(query)
                              }
                            "
                            :searchable="true"
                            :can-clear="false"
                            :classes="multiSelectClasses"
                            @open="
                              (select$) => {
                                if (select$.noOptions) {
                                  select$.resolveOptions()
                                }
                              }
                            "
                          />
                        </div>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <div>
                          <label
                            class="block text-left text-xs font-bold text-gray-700"
                            for="active"
                          >
                            Jumlah
                          </label>
                        </div>
                        <div class="flex">
                          <input
                            v-model="subVariantIngredientTotals[key]"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            type="number"
                            min="0"
                            placeholder="Jumlah"
                          />
                        </div>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <div>
                          <label
                            class="block text-left text-xs font-bold text-gray-700"
                            for="active"
                          >
                            Satuan
                          </label>
                        </div>
                        <div class="flex w-full items-center space-x-2">
                          <select
                            v-model="subVariantIngredientStocks[key]"
                            class="w-full appearance-none rounded border-2 p-2 leading-tight text-gray-700 focus:outline-none focus:ring-0"
                            :class="[
                              subVariantIngredients[key] === undefined ||
                              subVariantIngredients[key] === null
                                ? 'cursor-not-allowed bg-gray-200'
                                : 'cursor-pointer focus:border-primary focus:bg-white',
                            ]"
                          >
                            <template
                              v-if="
                                getMasterUnitIngredient(
                                  subVariantIngredients[key]
                                ) === null
                              "
                            >
                              <option
                                v-for="unit in unitData"
                                :key="unit.id"
                                :value="unit.id"
                              >
                                {{ unit.name }}
                              </option>
                            </template>
                            <template v-else>
                              <option
                                v-for="unit in unitData.filter(
                                  (item) =>
                                    getMasterUnitIngredient(
                                      subVariantIngredients[key]
                                    ) === item.base
                                )"
                                :key="unit.id"
                                :value="unit.id"
                              >
                                {{ unit.name }}
                              </option>
                            </template>
                          </select>
                          <TrachIcon
                            v-show="
                              productIngredientSubVariantTotal -
                                subVariantIngredientDeletedKey.length >
                              1
                            "
                            class="cursor-pointer"
                            @click="deleteSubVariantIngredient(key)"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <p
                    class="cursor-pointer pb-4 text-left text-gray-700 hover:text-gray-900"
                    @click="productIngredientSubVariantTotal += 1"
                  >
                    + Tambah komponen baru
                  </p>
                </div>
              </div>
              <div class="flex justify-end space-x-2 px-5 pb-8">
                <button
                  type="button"
                  class="btn w-24"
                  @click="addSubVariantMode = false"
                >
                  {{ $t('button.cancel') }}
                </button>
                <button type="submit" class="btn btn-primary w-24">
                  {{ $t('button.save') }}
                </button>
              </div>
            </Form>
          </ModalBody>
          <ModalBody v-else class="p-4">
            <Form
              ref="form"
              v-slot="{ errors }"
              :validation-schema="variantSchema"
              @submit="saveVariant"
            >
              <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
                <div class="flex flex-col space-y-3">
                  <div class="flex justify-between">
                    <p class="font-bold">
                      <span v-if="!isFormEdit"> Tambah Variant </span>
                      <span v-else> Edit Variant </span>
                    </p>
                  </div>
                </div>

                <div class="flex flex-col">
                  <div class="flex w-full flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="variant-name"
                      >
                        Nama Variant *
                      </label>
                    </div>
                    <div class="text-left">
                      <Field
                        v-slot="{ field }"
                        v-model="variantName"
                        name="variantName"
                      >
                        <input
                          id="variantName"
                          v-bind="field"
                          required="required"
                          class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                          :class="[
                            errors.variantName !== undefined
                              ? 'border-red-400'
                              : 'border-gray-200',
                          ]"
                          type="text"
                          placeholder="Nama Variant"
                        />
                      </Field>
                      <span class="text-xs text-red-400">
                        <template v-if="errors.variantName !== undefined">
                          {{ $t(errors.variantName) }}
                        </template>
                      </span>
                    </div>
                  </div>

                  <div class="mt-6 flex w-full flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="variant-option"
                      >
                        Sub-variant
                      </label>
                    </div>
                  </div>
                  <div class="flex w-full justify-between">
                    <table class="table-report table text-xs">
                      <tr>
                        <th>Nama sub-variant</th>
                        <th>Biaya tambahan</th>
                        <th>Bahan tersambung</th>
                        <th>{{ $t('action.action') }}</th>
                      </tr>
                      <tr v-for="sVariant in subVariantData" :key="sVariant.id">
                        <td>{{ sVariant.name }}</td>
                        <td>
                          <Currency :total-in-string="sVariant.price" />
                        </td>
                        <td>
                          {{ sVariant.ingredients.length }} bahan tersambung
                        </td>
                        <td class="space-x-2">
                          <span
                            class="cursor-pointer font-medium text-primary"
                            @click="editSubVariant(sVariant)"
                          >
                            {{ $t('action.edit') }}
                          </span>
                          <span
                            class="cursor-pointer font-medium text-danger"
                            @click="deleteSubVariant(sVariant.id)"
                          >
                            {{ $t('action.delete') }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="cursor-pointer text-left text-xs text-gray-500 hover:text-gray-900"
                          colspan="4"
                          @click="toggleSubVariantMode"
                        >
                          + Tambah sub-variant baru
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div
                    class="mt-6 flex w-full flex-col space-y-2 text-left text-xs"
                  >
                    <p class="font-bold">
                      Apakah pengguna harus memilih opsi ini?
                    </p>
                    <div class="flex flex-col space-y-2">
                      <div>
                        <input
                          v-model="variantIsRequired"
                          type="radio"
                          :value="true"
                          class="text-primary"
                        />
                        Ya, harus
                      </div>
                      <div>
                        <input
                          v-model="variantIsRequired"
                          type="radio"
                          class="text-primary"
                          :value="false"
                        />
                        Tidak, hanya optional
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-6 flex w-full flex-col space-y-2 text-left text-xs"
                  >
                    <p class="font-bold">
                      Berapa banyak pengguna dapat memilih opsi ini?
                    </p>
                    <div class="flex flex-col space-y-2">
                      <div>
                        <input
                          v-model="variantIsMultipleOption"
                          type="radio"
                          :value="false"
                          class="text-primary"
                        />
                        Hanya 1
                      </div>
                      <div>
                        <input
                          v-model="variantIsMultipleOption"
                          type="radio"
                          class="text-primary"
                          :value="true"
                        />
                        Lebih dari 1
                      </div>
                      <div
                        v-if="variantIsMultipleOption"
                        class="flex w-1/2 items-center space-x-2 px-4"
                      >
                        <div>
                          <label
                            class="block text-left text-xs font-bold text-gray-700"
                            for="variant-min-selection"
                          >
                            Pilihan minimal
                          </label>
                          <input
                            id="variant-min-selection"
                            v-model="variantMinSelection"
                            required="required"
                            class="w-1/2 appearance-none rounded border p-1 text-center text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            type="text"
                          />
                        </div>
                        <div>
                          <label
                            class="block text-left text-xs font-bold text-gray-700"
                            for="variant-min-selection"
                          >
                            Pilihan maksimal
                          </label>
                          <input
                            id="variant-max-selection"
                            v-model="variantMaxSelection"
                            required="required"
                            class="w-1/2 appearance-none rounded border p-1 text-center text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-6 flex w-full flex-col space-y-2">
                    <div>
                      <label
                        class="block text-left text-xs font-bold text-gray-700"
                        for="variant-option"
                      >
                        Produk terhubung
                      </label>
                    </div>
                  </div>
                  <div class="flex w-full justify-between">
                    <table class="table-report table text-xs">
                      <tr>
                        <th>Produk terhubung</th>
                        <th>Harga produk</th>
                        <th>Kesediaan</th>
                        <th>{{ $t('action.action') }}</th>
                      </tr>
                      <tr>
                        <td
                          v-if="isAddProduct"
                          colspan="4"
                          class="cursor-pointer text-gray-500 hover:text-gray-900"
                        >
                          <Multiselect
                            v-model="newSelectedProduct"
                            value="product_id"
                            label="product_name"
                            track-by="product_name"
                            :placeholder="$t('formLabel.select.product')"
                            :min-chars="0"
                            :resolve-on-load="true"
                            :infinite="true"
                            :limit="filterData.take"
                            :delay="1000"
                            :options="
                              async (query) => {
                                return await searchProductOption(
                                  query,
                                  productLinkedIds
                                )
                              }
                            "
                            :searchable="true"
                            :can-clear="false"
                            :classes="multiSelectClasses"
                            @open="
                              (select$) => {
                                if (select$.noOptions) {
                                  select$.resolveOptions()
                                }
                              }
                            "
                            @change="selectedProduct"
                          />
                        </td>
                        <td
                          v-else
                          colspan="4"
                          class="cursor-pointer text-gray-500 hover:text-gray-900"
                          @click="isAddProduct = true"
                        >
                          <span> + Tambah produk </span>
                        </td>
                      </tr>
                      <tr
                        v-for="productData in productLinkedData"
                        :key="productData.id"
                      >
                        <td>{{ productData.name }}</td>
                        <td>
                          <Currency :total-in-string="productData.price" />
                        </td>
                        <td>
                          <div>
                            <p
                              class="rounded-full px-1 py-2 text-center text-white"
                              :class="[
                                productData.is_available
                                  ? 'bg-green-500'
                                  : 'bg-red-500',
                              ]"
                            >
                              <span v-if="productData.is_available">
                                Tersedia
                              </span>
                              <span v-else> Kosong </span>
                            </p>
                          </div>
                        </td>
                        <td class="space-x-2">
                          <span
                            class="cursor-pointer font-medium text-danger"
                            @click="deleteLinkedProduct(productData.id)"
                          >
                            {{ $t('action.delete') }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="flex justify-end space-x-2 px-5 pb-8">
                <button
                  type="button"
                  class="btn w-24"
                  @click="showModal = false"
                >
                  {{ $t('button.cancel') }}
                </button>
                <button type="submit" class="btn btn-primary w-24">
                  {{ $t('button.save') }}
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        <!-- END: Modal Add Variant -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <DeleteModal
          :id="deleteId"
          :show-modal="showModalDel"
          @yes="confirmDelete($event)"
          @no="showModalDel = false"
        />

        <LoadingIndicator v-if="isLoading || isLoadingSave" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import productMixin from '@/mixins/product.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

import Currency from '@/components/Currency.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import Multiselect from '@vueform/multiselect'

import { Form, Field } from 'vee-validate'

import BookIcon from '@/assets/svg/menu/book.svg'
import TrachIcon from '@/assets/svg/trash.svg'

export default {
  name: 'IngredientListPage',
  components: {
    Breadcrumb,
    TableComponent,
    ButtonComponent,

    Currency,
    DeleteModal,
    Multiselect,
    BookIcon,
    TrachIcon,
    Form,
    Field,
  },
  mixins: [globalMixin, productMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },
  data: () => ({
    variantListHeader: [],
    variantListFieldHeader: [
      {
        item: 'page.product.variantList.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.product.variantList.tableHeaders.subVariant',
        customClass: '',
      },
      {
        item: 'page.product.variantList.tableHeaders.totalProduct',
        customClass: 'text-center',
      },
    ],
    variantListEditHeader: [
      {
        item: 'page.product.variantList.tableHeaders.action',
        customClass: '',
      },
    ],

    // FORM
    variantId: null,
    variantName: '',
    variantIsRequired: false,
    variantIsMultipleOption: false,
    variantMinSelection: 1,
    variantMaxSelection: 2,
    totalSubVariant: 1,

    subVariantData: [],
    productLinkedData: [],
    productLinkedIds: [],

    addSubVariantMode: false,
    isFormEdit: false,

    isAddProduct: false,
    newSelectedProduct: '',
    newSelectedProductObj: {},

    // Sub variant
    subVariantId: null,
    subVariantName: '',
    subVariantPrice: 0,
    productIngredientSubVariantTotal: 1,
    subVariantIngredientIds: [],
    subVariantIngredients: [],
    subVariantIngredientTotals: [],
    subVariantIngredientStocks: [],
    subVariantIngredientDeletedKey: [],

    // Form Validation
    variantSchema: Yup.object().shape({
      variantName: Yup.string()
        .typeError('formError.variantName.required')
        .required('formError.variantName.required'),
    }),
    subVariantSchema: Yup.object().shape({
      subVariantName: Yup.string()
        .typeError('formError.subVariantName.required')
        .required('formError.subVariantName.required'),
    }),
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllIngredient(false, false, null, '', false)
        this.getAllProduct(false, false, false, '', false)
        this.getAllVariant(true, true, true)
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllIngredient(false, false, null, '', false)
          this.getAllProduct(false, false, false, '', false)
          this.getAllVariant(true, true, true)
          this.isInstitute = true
        })
      }
      this.getAllUnit(false)

      this.variantListHeader = [...this.variantListFieldHeader]
    }
    this.showModal = false
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllVariant(false, true, true)
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllIngredient(false)
      this.getAllProduct(false)

      this.getAllVariant(false, true, true)
    },
    extractOptions(optionsData) {
      let options = ''
      optionsData.forEach((item, index) => {
        options += item.name
        if (index >= 0 && index !== optionsData.length - 1) options += ', '
      })

      return options
    },
    resetData(afterSave = true) {
      this.addSubVariantMode = false
      this.variantId = null
      this.variantName = ''
      this.variantIsRequired = false
      this.variantIsMultipleOption = false
      this.variantMinSelection = 1
      this.variantMaxSelection = 2
      this.totalSubVariant = 1
      this.subVariantData = []
      this.productLinkedData = []
      this.productLinkedIds = []

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            variantName: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showModal = false
        this.isEdit = false
        this.isFormEdit = false
        this.getAllVariant(false, false, true)
        this.variantListHeader = [...this.variantListFieldHeader]
      }
    },
    showModalAdd() {
      this.showModal = true
      this.isFormEdit = false
      this.resetData(false)
    },
    showModalUpdate(data) {
      this.addSubVariantMode = false
      this.showModal = true
      this.isFormEdit = true
      this.variantId = data.id
      this.variantName = data.name
      this.variantIsRequired = data.is_required
      this.variantIsMultipleOption = data.is_multiple_option
      this.variantMinSelection = data.min_selection
      this.variantMaxSelection = data.max_selection
      this.totalSubVariant = data.options.length

      const options = data.options
      const subVariantData = options.reduce((val, item, idx) => {
        val.push({
          id: item.id,
          order: idx + 1,
          pseudoId: idx + 1,
          name: item.name,
          is_available: item.is_available === 1,
          price: item.price,
          ingredients: item.map_ingredients,
        })
        return val
      }, [])
      this.subVariantData = subVariantData

      const productLinked = data.product_linked
      this.productLinkedData = productLinked.reduce((val, item) => {
        val.push({
          id: item.product.id,
          name: item.product.name,
          is_available: item.product.is_available === 1,
          price: item.product.price,
        })
        return val
      }, [])

      this.productLinkedIds = this.productLinkedData.reduce((val, item) => {
        val.push(item.id)
        return val
      }, [])
    },
    edited() {
      this.isEdit = true
      this.variantListHeader.push(...this.variantListEditHeader)
    },
    cancelEdit() {
      this.isEdit = false
      this.variantListHeader = [...this.variantListFieldHeader]
    },
    saveEdited() {
      this.isEdit = false
      this.variantListHeader = [...this.variantListFieldHeader]
    },
    confirmDelete(id) {
      this.deleteVariantAPI(id)
    },
    saveVariant() {
      const params = {}
      params.name = this.variantName
      params.tenant_id = this.tenantId
      params.is_required = this.variantIsRequired
      params.is_multiple_option = this.variantIsMultipleOption
      params.min_selection = this.variantIsMultipleOption
        ? parseInt(this.variantMinSelection)
        : 0
      params.max_selection = this.variantIsMultipleOption
        ? parseInt(this.variantMaxSelection)
        : 0

      params.options = this.subVariantData

      params.product_linked = this.productLinkedIds

      if (this.variantId != null) {
        params.id = this.variantId
        this.saveVariantAPI(params, true)
      } else {
        this.saveVariantAPI(params)
      }
    },
    editSubVariant(data) {
      this.addSubVariantMode = true

      this.subVariantId = data.id
      this.subVariantName = data.name
      this.subVariantPrice = data.price
      const subVariantIngredientIds = []
      const subVariantIngredients = []
      const subVariantIngredientTotals = []
      const subVariantIngredientStocks = []

      data.ingredients.forEach((item) => {
        if ('product_ingredient' in item) {
          subVariantIngredientIds.push(item.id)
          subVariantIngredients.push(item.product_ingredient.id)
          subVariantIngredientTotals.push(item.quantity)
          subVariantIngredientStocks.push(item.master_unit_id)

          this.ingredientDataSearch.push({
            ingredient_id: item.product_ingredient.id,
            ingredient_name: item.product_ingredient.name,
            value: item.product_ingredient.id,
            label: item.product_ingredient.name,
          })
          this.ingredientDataSearchOption.push({
            ingredient_id: item.product_ingredient.id,
            ingredient_name: item.product_ingredient.name,
            value: item.product_ingredient.id,
            label: item.product_ingredient.name,
          })
        } else {
          subVariantIngredientIds.push(null)
          subVariantIngredients.push(item.ingredient_id)
          subVariantIngredientTotals.push(item.quantity)
          subVariantIngredientStocks.push(item.master_unit_id)
        }
      })

      this.subVariantIngredientIds = subVariantIngredientIds
      this.subVariantIngredients = subVariantIngredients
      this.subVariantIngredientTotals = subVariantIngredientTotals
      this.subVariantIngredientStocks = subVariantIngredientStocks
      this.productIngredientSubVariantTotal = subVariantIngredients.length
    },
    deleteSubVariant(id) {
      const index = this.subVariantData.findIndex((data) => data.id === id)
      if (index > -1) {
        this.subVariantData.splice(index, index >= 0 ? 1 : 0)
      }
    },
    toggleSubVariantMode() {
      this.addSubVariantMode = true
      this.subVariantName = ''
      this.subVariantPrice = 0

      this.subVariantIngredients = []
      this.subVariantIngredientTotals = []
      this.subVariantIngredientStocks = []
      this.subVariantIngredientDeletedKey = []
      this.productIngredientSubVariantTotal = 1
    },
    saveSubVariant() {
      const dataSubVariant = {}
      dataSubVariant.pseudoId = this.subVariantData.length + 1
      dataSubVariant.order = this.subVariantData.length + 1
      dataSubVariant.name = this.subVariantName
      dataSubVariant.price = this.subVariantPrice
      dataSubVariant.is_available = true

      const dataIngredients = []
      this.subVariantIngredients.forEach((item, key) => {
        if (!this.subVariantIngredientDeletedKey.includes(key)) {
          const params = {
            ingredient_id: item,
            quantity: this.subVariantIngredientTotals[key],
            master_unit_id: this.subVariantIngredientStocks[key],
          }
          if (this.subVariantIngredientIds[key] !== null) {
            params.id = this.subVariantIngredientIds[key]
          }
          dataIngredients.push(params)
        }
      })
      dataSubVariant.ingredients = dataIngredients

      if (this.subVariantId !== null) {
        this.deleteSubVariant(this.subVariantId)

        this.subVariantData.push(dataSubVariant)
        this.subVariantId = null
      } else {
        this.subVariantData.push(dataSubVariant)
      }
      this.addSubVariantMode = false
      this.subVariantIngredientDeletedKey = []
    },
    deleteSubVariantIngredient(key) {
      this.subVariantIngredientDeletedKey.push(key)
      // this.productIngredientSubVariantTotal -= 1
    },
    selectedProduct($event) {
      this.isAddProduct = false

      const selectedProduct = this.productData.find(
        (item) => item.id === $event
      )

      if (selectedProduct !== undefined) {
        this.newSelectedProductObj = selectedProduct
        this.productLinkedData.push(this.newSelectedProductObj)
        this.productLinkedIds.push($event)
      }
    },
    deleteLinkedProduct(id) {
      const index = this.productLinkedIds.indexOf(id)
      if (index > -1) {
        this.productLinkedIds.splice(index, index >= 0 ? 1 : 0)
      }

      const productLinkedIndex = this.productLinkedData.findIndex(
        (data) => data.id === id
      )
      if (productLinkedIndex > -1) {
        this.productLinkedData.splice(
          productLinkedIndex,
          productLinkedIndex >= 0 ? 1 : 0
        )
      }
    },
  },
}
</script>
