<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex w-full">
        <Breadcrumb
          :title="$t('menu.analytic.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
      <div class="flex w-full items-center gap-2">
        <FilterTimeRange
          class="w-full md:w-fit"
          :active="periodicFilter"
          @change-filter="changeFilterTimeRange($event)"
        />

        <div v-if="isInstitute" class="flex rounded-lg shadow-sm">
          <select
            v-model="tenantId"
            class="w-full rounded-lg border pr-10 text-sm outline-none"
            @change="changeFilterDropdown"
          >
            <option
              v-for="tenant in tenantOptions"
              :key="tenant.value"
              :value="tenant.value"
            >
              {{ tenant.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Average Transaction -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex">
                  <div class="flex self-center align-middle">
                    <h2 class="mr-3 truncate text-lg font-medium">
                      {{ $t($route.meta.title) }}
                    </h2>
                    <span
                      class="flex self-center text-xs uppercase text-gray-500"
                    >
                      {{ periodicFilter }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-12 gap-6 md:mt-5">
              <Card
                :is-up="cardData.transaction_average.is_up"
                :title="$t('page.analytic.averageTransaction.value')"
                :total="cardData.transaction_average.total"
                :percentage="cardData.transaction_average.percent"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
              <Card
                :is-up="cardData.transaction_total.is_up"
                :is-currency="false"
                :title="$t('page.analytic.averageTransaction.totalTransaction')"
                :total="cardData.transaction_total.total"
                :percentage="cardData.transaction_total.percent"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
              <Card
                :is-up="cardData.sales_total.is_up"
                :title="$t('page.analytic.averageTransaction.sales')"
                :total="cardData.sales_total.total"
                :percentage="cardData.sales_total.percent"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
            </div>
          </div>
          <!-- END: Average Transaction -->
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Total Transaction -->
          <div class="col-span-12 mt-4 2xl:col-span-6">
            <div class="intro-y box mt-3 px-1 py-5 md:mt-6 md:p-5">
              <div class="flex flex-col md:flex-row md:items-center">
                <div class="flex items-center self-center align-middle">
                  <h2 class="mr-3 truncate text-lg font-medium">
                    {{
                      $t('page.analytic.averageTransaction.totalTransaction')
                    }}
                  </h2>
                  <span
                    class="flex self-center text-xs uppercase text-gray-500"
                  >
                    {{ periodicFilter }}
                  </span>
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!transactionChartEmpty"
                  :height="275"
                  :label="transactionChartLabel"
                  :item-name="transactionChartItemName"
                  :data="transactionChartData"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
            </div>
          </div>
          <!-- END: Total Transaction -->

          <!-- BEGIN: Total Sales -->
          <div class="col-span-12 md:mt-4 2xl:col-span-6">
            <div class="intro-y box mt-3 px-1 py-5 md:mt-6 md:p-5">
              <div class="flex flex-col md:flex-row md:items-center">
                <div class="flex items-center self-center align-middle">
                  <h2 class="mr-3 truncate text-lg font-medium">
                    {{ $t('page.analytic.averageTransaction.sales') }}
                  </h2>
                  <span
                    class="flex self-center text-xs uppercase text-gray-500"
                  >
                    {{ periodicFilter }}
                  </span>
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!salesChartEmpty"
                  :height="275"
                  :label="salesChartLabel"
                  :item-name="salesChartItemName"
                  :data="salesChartData"
                  class="mt-6 -mb-6"
                />
              </div>
            </div>
          </div>
          <!-- END: Total Sales -->
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Sales Detail -->
          <TableComponent
            :date-string="periodicFilter"
            :title="$t('page.analytic.averageTransaction.salesDetal')"
            :header="salesReportHeader"
            :is-show-export="true"
            :is-not-empty="salesReportData.length > 0"
            :meta-data="metaData"
            @change-filter="changeFilter($event)"
            @do-export="downloadAverageTransaction"
          >
            <tr v-for="data in salesReportData" :key="data.order_number">
              <td>{{ data.customer_name || '-' }}</td>
              <td>
                {{
                  formatDateLocale(data.transaction_time, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    hour12: false,
                    timeZone: 'Asia/Jakarta',
                    timeZoneName: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })
                }}
              </td>
              <td>{{ data.order_number }}</td>
              <td>{{ data.payment_method }}</td>
              <td class="text-center">
                <Currency
                  :total-in-string="data.payment_amount"
                  wrap-class="justify-center"
                />
              </td>
            </tr>
          </TableComponent>
          <!-- END: Sales Detail -->
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import SaleIcon from '@/assets/svg/sale.svg'

import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Card from '@/components/Card.vue'
import FilterTimeRange from '@/components/FilterTimeRange.vue'
import NoData from '@/components/NoData.vue'

export default {
  name: 'AverageTransactionPage',
  components: {
    Breadcrumb,
    SaleIcon,
    TableComponent,
    Currency,
    Card,
    ReportLineChart,
    FilterTimeRange,
    NoData,
  },
  mixins: [globalMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const periodicFilter = ref('daily')

    return {
      t,
      n,
      store,
      route,
      router,
      periodicFilter,
    }
  },
  data: () => ({
    salesChartLabel: [],
    salesChartItemName: '',
    salesChartData: [],
    salesChartEmpty: false,
    transactionChartLabel: [],
    transactionChartItemName: '',
    transactionChartData: [],
    transactionChartEmpty: false,
    salesReportHeader: [
      {
        item: 'page.analytic.averageTransaction.tableHeaders.customerName',
        customClass: '',
      },
      {
        item: 'page.analytic.averageTransaction.tableHeaders.time',
        customClass: '',
      },
      {
        item: 'page.analytic.averageTransaction.tableHeaders.orderNo',
        customClass: '',
      },
      {
        item: 'page.analytic.averageTransaction.tableHeaders.paymentMethod',
        customClass: '',
      },
      {
        item: 'page.analytic.averageTransaction.tableHeaders.paymentTotal',
        customClass: 'text-center',
      },
    ],
    salesReportData: [],
    cardData: {
      transaction_average: {
        total: 0,
        percent: '0.00%',
        is_up: false,
      },
      sales_total: {
        total: 0,
        percent: '0.00%',
        is_up: false,
      },
      transaction_total: {
        total: 0,
        percent: '0.00%',
        is_up: false,
      },
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id

        this.init()

        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.init()
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    init() {
      this.getSummary()
      this.getAverageTransaction()
      this.getAverageTransactionChart()
    },
    async getSummary() {
      this.isLoading = true

      const params = {
        periodic: this.periodicFilter,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.store
        .dispatch('analytic/transactionAverageSummary', params)
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.transaction_average = responseData.transaction_average
            this.cardData.sales_total = responseData.sales_total
            this.cardData.transaction_total = responseData.transaction_total
          }
          this.message = response.data.message
          this.showToast('success')
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          console.log(this.message)
          // this.showToast('failed')
        })
    },
    async getAverageTransactionChart() {
      const params = {
        periodic: this.periodicFilter,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.store
        .dispatch('analytic/transactionAverageChart', params)
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            const objectKey = Object.keys(responseData)
            if (responseData.sales_total.length > 0) {
              this.salesChartItemName = objectKey.at(0)
              let salesLabel = []
              let salesTotal = []
              responseData.sales_total.forEach((item) => {
                salesLabel.push(item.name)
                salesTotal.push(item.total)
              })
              this.salesChartLabel = salesLabel
              this.salesChartData = salesTotal
              this.salesChartEmpty = false
            } else {
              this.salesChartEmpty = true
            }

            if (responseData.transaction_total.length > 0) {
              this.transactionChartItemName = objectKey.at(-1)
              let transactionLabel = []
              let transactionTotal = []
              responseData.transaction_total.forEach((item) => {
                transactionLabel.push(item.name)
                transactionTotal.push(item.total)
              })
              this.transactionChartData = transactionTotal
              this.transactionChartLabel = transactionLabel
              this.transactionChartEmpty = false
            } else {
              this.transactionChartEmpty = true
            }
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async getAverageTransaction() {
      const params = {
        periodic: this.periodicFilter,
        page: this.filterData.page,
        take: this.filterData.take,
        order: this.filterData.order,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.store
        .dispatch('analytic/transactionAverage', params)
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.salesReportData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async downloadAverageTransaction(fileType) {
      const params = {
        periodic: this.periodicFilter,
        file_type: fileType,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.store
        .dispatch('analytic/transactionAverageDownload', params)
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'average_transaction.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.init()
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAverageTransaction()
    },
    changeFilterDropdown() {
      this.init()
    },
  },
}
</script>
